import axios from 'axios';

import { unformatObject } from 'app/utils/reducerUtils';
import { showGlobalError } from 'app/actions/uiActions';

export function fetchInsurers() {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios
      .get(`${CORE_API_URL}/insurers`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { 'page[size]': 1000 },
      })
      .then((response) => dispatch(receiveInsurers(response.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function fetchInsurer(id) {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios
      .get(`${CORE_API_URL}/insurers/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => dispatch(receiveInsurer(response.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function createInsurer(params) {
  return function (dispatch, getState) {
    dispatch(receiveInsurerLoading(true));

    return axios
      .post(
        `${CORE_API_URL}/insurers`,
        { insurer: unformatObject(params) },
        {
          headers: {
            Authorization: `Bearer ${getState().session.coreServiceToken}`,
          },
        }
      )
      .then((response) => dispatch(receiveInsurer(response.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function updateInsurer(insurerId, params) {
  return function (dispatch, getState) {
    dispatch(receiveInsurerLoading(true));

    return axios
      .patch(
        `${CORE_API_URL}/insurers/${insurerId}`,
        { insurer: unformatObject(params) },
        {
          headers: {
            Authorization: `Bearer ${getState().session.coreServiceToken}`,
          },
        }
      )
      .then((response) => dispatch(receiveInsurer(response.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function receiveInsurers(insurers) {
  return {
    type: 'RECEIVE_INSURERS',
    insurers,
  };
}

export function receiveInsurer(insurer) {
  return {
    type: 'RECEIVE_INSURER',
    insurer,
  };
}

export function receiveInsurerLoading(loading) {
  return {
    type: 'RECEIVE_INSURER_LOADING',
    loading,
  };
}
