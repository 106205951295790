import merge from 'lodash/merge';
import assign from 'lodash/merge';
import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  coreServiceToken: null,
  currentUser: {},
  error: {},
  features: [],
  isAuthenticated: false,
  isExpirationMessageShown: false,
  isLoading: false,
  reauthenticate: false,
  sessionLoading: false,
});

export { nullState };

export default (state = nullState, action) => {
  Object.freeze(state);
  const coreServiceToken = action.coreServiceToken;
  const features = action.features;
  let currentUser = action.currentUser;
  let error = action.error;
  const sessionLoading = action.sessionLoading;

  switch (action.type) {
    case 'RECEIVE_LOGIN':
      localStorage.setItem('coreServiceToken', coreServiceToken);
      return merge({}, state, { coreServiceToken, features });
    case 'RECEIVE_CURRENT_USER':
      currentUser = formatData(currentUser);
      return merge({}, state, {
        currentUser,
        isAuthenticated: true,
        isExpirationMessageShown: false,
        reauthenticate: false,
        sessionLoading: false,
      });
    case 'RECEIVE_LOGOUT':
      localStorage.removeItem('userId');
      localStorage.removeItem('coreServiceToken');
      return assign({}, nullState);
    case 'RECEIVE_LOGIN_ERROR':
      return merge({}, nullState, {
        error: error
          ? {
              status: error.response?.status,
              message: error.message,
            }
          : nullState.error,
        isLoading: false,
      });
    case 'SESSION_LOAD':
      error = sessionLoading ? { status: null, message: '' } : state.error;
      return merge({}, state, { error, sessionLoading });
    case 'RECEIVE_EXPIRED_SESSION_WARNING':
      return {
        ...state,
        isExpirationMessageShown: action.isExpirationMessageShown,
      };
    case 'RECEIVE_REAUTHENTICATION_PROMPT_STATUS':
      return { ...state, reauthenticate: action.status };
    case 'TOGGLE_LOADING':
      return { ...state, isLoading: !state.isLoading };
    default:
      return state;
  }
};
