import { cloneDeep, mergeWith } from 'lodash';

import { formatData, overwriteNonPrimitive } from 'app/utils/reducerUtils';

export const nullState = Object.freeze({
  escalation: null,
  escalations: [],
  loading: false,
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_ESCALATIONS': {
      const escalations = action.escalations?.data;

      return mergeWith(
        {},
        state,
        {
          escalations: escalations.map(formatData),
          loading: false,
        },
        overwriteNonPrimitive
      );
    }

    case 'RECEIVE_ESCALATION': {
      const receivedEscalation = formatData(action.escalation.data);
      const escalations = cloneDeep(state.escalations);

      const index = escalations
        .map(({ id }) => parseInt(id, 10))
        .indexOf(parseInt(receivedEscalation?.id, 10));

      if (index !== -1) {
        escalations.splice(index, 1, receivedEscalation);
      } else {
        escalations.unshift(receivedEscalation);
      }

      return mergeWith(
        {},
        state,
        {
          escalations,
          loading: false,
        },
        overwriteNonPrimitive
      );
    }

    case 'REMOVE_ESCALATION': {
      const escalations = cloneDeep(state.escalations);

      const newEscalations = escalations.filter(
        ({ id }) => parseInt(id, 10) !== parseInt(action.escalationId, 10)
      );

      return mergeWith(
        {},
        state,
        {
          escalations: newEscalations,
          loading: false,
        },
        overwriteNonPrimitive
      );
    }

    default:
      return state;
  }
};
