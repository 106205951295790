import React from 'react';

import { Box } from 'grommet';

import FeatureSwitch from 'app/components/Common/FeatureSwitch';

import styled, { css } from 'styled-components';

const SideMenuItem = ({
  sideMenuOpen,
  icon,
  title,
  route,
  pathname,
  handleClick,
  featureSwitch,
}) => {
  const openContent = sideMenuOpen && (
    <Box margin={{ right: 'large' }}>{title}</Box>
  );

  const isSideMenuItemActive = pathname.includes(route);

  const content = (
    <SideMenuItemContainer
      flex
      direction="row"
      justify="start"
      align="center"
      onClick={handleClick}
      isActive={isSideMenuItemActive}
    >
      <Box justify="center" align="center" pad={{ horizontal: 'medium' }}>
        {icon}
      </Box>
      {openContent}
    </SideMenuItemContainer>
  );

  return featureSwitch ? (
    <FeatureSwitch name={featureSwitch} state="on">
      {content}
    </FeatureSwitch>
  ) : (
    content
  );
};

export default SideMenuItem;

const SideMenuItemContainer = styled(Box)`
  min-height: 2em;

  &:hover {
    background: #51799e;
  }

  ${(props) =>
    props.isActive &&
    css`
      background: #51799e;
    `}
`;
