import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { Box, Text } from 'grommet';
import { Close, StatusCritical } from 'grommet-icons';
import PropTypes from 'prop-types';

import Button from 'app/components/Common/Styled/Grommet/Button';

import { AnimatedBox } from './styles';

const HIDDEN_OFFSET = -300; // Render 300px offscreen when hidden
const VISIBLE_OFFSET = 0; // Render 0px offscreen when visible
const ANIMATE_FOR = 1000; // Animate for 1000ms when toggling visibility
const VISIBLE_FOR = 8000; // Display for 8000ms after becoming visible

const propTypes = {
  errors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

const AppErrors = ({ errors, onClose }) => {
  const [translateY, setTranslateY] = useState({});

  const disappear = useCallback(
    (id) => {
      const changes = { ...translateY, [id]: HIDDEN_OFFSET };
      setTranslateY(changes);
    },
    [translateY]
  );

  const close = useCallback(
    (id) => {
      disappear(id);
      setTimeout(() => onClose(id), ANIMATE_FOR + 1);
    },
    [disappear, onClose]
  );

  const appear = useCallback(
    (id) => {
      const changes = { ...translateY, [id]: VISIBLE_OFFSET };

      setTranslateY(changes);
      setTimeout(() => close(id), VISIBLE_FOR + 1);
    },
    [close, translateY]
  );

  useEffect(() => {
    errors.forEach(({ id }) => {
      if (typeof translateY[id] !== 'undefined') return;

      disappear(id);
      setTimeout(() => appear(id), 10);
    });

    Object.keys(translateY).forEach((id) => {
      if (!errors.find((error) => error.id === id)) delete translateY[id];
    });
  }, [appear, disappear, errors, translateY]);

  return (
    <Fragment>
      {errors.map(({ message, id }) => (
        <AnimatedBox
          key={id}
          animateFor={ANIMATE_FOR}
          translateY={
            typeof translateY[id] === undefined ? HIDDEN_OFFSET : translateY[id]
          }
          direction="row"
          justify="between"
          background="light-2"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Box align="center" direction="row" gap="medium">
            <StatusCritical size="large" color="status-critical" />
            <Text>{message}</Text>
          </Box>

          <Button plain icon={<Close />} onClick={() => close(id)} />
        </AnimatedBox>
      ))}
    </Fragment>
  );
};

export default AppErrors;

AppErrors.propTypes = propTypes;
