import React, { Component } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Anchor,
  Box,
  Button,
  FormField,
  Image,
  Layer,
  Text,
  TextInput,
} from 'grommet';

import carrumLogo from 'app/assets/img/carrum_logo.png';

import MfaMessage from './MfaMessage';

export default class Login extends Component {
  static propTypes = {
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    loginUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: '',
    isLoading: false,
  };

  state = {
    email: '',
    password: '',
  };

  onSubmit = async (event) => {
    if (event) event.preventDefault();

    const { email, password } = this.state;

    await this.props.loginUser({ email, password }, this.props.history);

    if (this.props.modal) window.dispatchEvent(new Event('focus'));
  };

  render() {
    const { modal, heading, error, isLoading } = this.props;

    const loginForm = (
      <Box
        data-testid="Login"
        round="xsmall"
        align="center"
        background="light-1"
        elevation="small"
        gap="small"
        pad="medium"
        width="medium"
      >
        <Image height="56px" src={carrumLogo} />

        <Text size="small" textAlign="center">
          {heading || 'Please sign in with your Carrum Health credentials'}
        </Text>

        <MfaMessage />

        <Form data-testid="Login--form" onSubmit={this.onSubmit}>
          <FormField width="full" label="Email">
            <TextInput
              data-testid="Login--email-input"
              name="email"
              type="email"
              value={this.state.email}
              onChange={({ target }) => this.setState({ email: target.value })}
            />
          </FormField>

          <FormField width="full" label="Password">
            <TextInput
              data-testid="Login--password-input"
              name="password"
              type="password"
              value={this.state.password}
              onChange={({ target }) =>
                this.setState({ password: target.value })
              }
            />
          </FormField>

          {error && error.length > 0 && (
            <Text
              color="status-critical"
              size="small"
              textAlign="center"
              margin={{ top: 'small' }}
            >
              {error}
            </Text>
          )}

          <Button
            data-testid="Login--submit-button"
            primary
            fill="horizontal"
            type="submit"
            label={this.props.isLoading ? 'Verifying...' : 'Log in'}
            margin={{ top: 'small' }}
            disabled={!this.state.email || !this.state.password || isLoading}
          />
        </Form>

        <Anchor
          data-testid="Login--forgot-passowrd-link"
          label="Forgot password?"
          href="/password-reset"
        />
      </Box>
    );

    return modal ? (
      <Layer align="center">{loginForm}</Layer>
    ) : (
      <Box flex align="center" alignSelf="center" justify="center">
        {loginForm}
      </Box>
    );
  }
}

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;
