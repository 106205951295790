"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.nullState = void 0;
var complianceActions_1 = require("app/actions/complianceActions");
var reducerUtils_1 = require("app/utils/reducerUtils");
var nullState = Object.freeze({
    complianceRecord: {},
    loading: false,
});
exports.nullState = nullState;
exports.default = (function (state, action) {
    if (state === void 0) { state = nullState; }
    switch (action.type) {
        case complianceActions_1.RECEIVE_COMPLIANCE_RECORD:
            return handleReceiveComplianceRecord(state, action);
        case complianceActions_1.RECEIVE_COMPLIANCE_RECORD_LOADING:
            return handleReceiveComplianceRecordLoading(state, action);
        default:
            return state;
    }
});
function handleReceiveComplianceRecord(state, action) {
    var complianceRecord = action.merge
        ? __assign(__assign({}, state.complianceRecord), (0, reducerUtils_1.formatData)(action.complianceRecord)) : (0, reducerUtils_1.formatData)(action.complianceRecord);
    return __assign(__assign({}, state), { complianceRecord: complianceRecord });
}
function handleReceiveComplianceRecordLoading(state, action) {
    return __assign(__assign({}, state), { loading: action.loading });
}
