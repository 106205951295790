import tableColumns, { defaultTableColumns } from 'app/utils/tableColumns';
import { formatData, parsePagination } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  type: 'users',
  pageSize: 18,
  records: [],
  filters: {},
  sortParam: 'updated_at',
  statusParam: 'active',
  prevPage: null,
  nextPage: null,
  lastPage: 1,
  currentPage: 1,
  loading: false,
  formShown: false,
  editing: false,
  infiniteLoad: false,
  updates: {},
  tableColumns,
});

export { nullState };

export default (state = nullState, action) => {
  switch (action.type) {
    case 'RECEIVE_RECORDS': {
      const records = action.records.data.map((data) => formatData(data));
      const pagination = action.records.links
        ? parsePagination(action.records.links)
        : nullState.pagination;

      return {
        ...state,
        ...pagination,
        records: action.append ? [...state.records, ...records] : records,
      };
    }
    case 'RECEIVE_GRID_CONFIGURATION':
      return { ...state, ...action.options };
    case 'RECEIVE_STATUS_PARAM':
      return { ...state, statusParam: action.statusParam };
    case 'RECEIVE_SORT_PARAM':
      return { ...state, sortParam: action.sortParam };
    case 'RECEIVE_FILTER_PARAM': {
      const filters = { ...state.filters, [action.filter]: action.value };

      if (!action.value) delete filters[action.filter];

      return { ...state, filters };
    }
    case 'RECEIVE_CLEAR_FILTERS':
      return { ...state, statusParam: 'active', sortParam: '', filters: {} };
    case 'RECEIVE_PAGE_SIZE_PARAM': {
      const pageSize = action.pageSize;
      return { ...state, pageSize: pageSize < 1 ? 1 : pageSize };
    }
    case 'RECEIVE_FORM_SHOWN':
      return { ...state, formShown: action.formShown };
    case 'RECEIVE_EDITING_STATUS':
      return { ...state, editing: action.editing };
    case 'RECEIVE_LOADING':
      return { ...state, loading: action.loading };
    case 'RECEIVE_INFINITE_LOAD':
      return { ...state, infiniteLoad: action.infiniteLoad };
    case 'RECEIVE_COLUMN_HIDDEN': {
      const columns = [...state.tableColumns[state.type]];
      const column = columns.find(({ key }) => key === action.columnKey);
      const hiddenColumnValues =
        JSON.parse(localStorage.getItem(`${state.type}.hiddenColumnValues`)) ||
        [];
      const isHidden = !hiddenColumnValues.includes(action.columnKey);

      // NOTE: The grid component doesn't hide columns well so several properties must
      // be set. See this link for info:
      // https://github.com/adazzle/react-data-grid/issues/917#issuecomment-583930246
      if (isHidden) {
        column.hidden = 'true';
        column.width = -1;
        column.name = '';

        hiddenColumnValues.push(action.columnKey);
      } else {
        const defaults = defaultTableColumns[state.type].find(
          ({ key }) => key === action.columnKey
        );

        delete column.hidden;
        column.width = defaults.width;
        column.name = defaults.name;

        const position = hiddenColumnValues.indexOf(action.columnKey);
        hiddenColumnValues.splice(position, 1);
      }

      localStorage.setItem(
        `${state.type}.hiddenColumnValues`,
        JSON.stringify(hiddenColumnValues)
      );

      return {
        ...state,
        tableColumns: { ...state.tableColumns, [state.type]: columns },
      };
    }
    case 'RECEIVE_COLUMN_WIDTH': {
      const columns = [...state.tableColumns[state.type]];
      const storedWidths = JSON.parse(
        localStorage.getItem(`${state.type}.columnWidths`) || '{}'
      );
      const width = Math.max(80, action.width);

      columns[action.columnIdx].width = width;
      storedWidths[columns[action.columnIdx].key] = width;
      localStorage.setItem(
        `${state.type}.columnWidths`,
        JSON.stringify(storedWidths)
      );

      return {
        ...state,
        tableColumns: { ...state.tableColumns, [state.type]: columns },
      };
    }
    case 'RECEIVE_GRID_UPDATE':
      return {
        ...state,
        updates: {
          ...state.updates,
          [action.idx]: {
            ...state.updates[action.idx],
            [action.column]: action.value,
          },
        },
      };
    case 'RESET_COLUMN_UPDATES':
      return {
        ...state,
        updates: {},
      };
    case 'RECEIVE_GRID_TYPE':
      return {
        ...nullState,
        type: action.gridType,
        statusParam: /features|system_jobs|round_robins/.test(action.gridType)
          ? 'active,inactive'
          : nullState.statusParam,
        sortParam: /clients|features|hospitals|insurers|procedures/.test(
          action.gridType
        )
          ? 'name'
          : /eligible_patient_load_errors/.test(action.gridType)
          ? 'id'
          : /paper_trails/.test(action.gridType)
          ? '-created_at'
          : /patients/.test(action.gridType)
          ? 'eligible_patients.last_name,eligible_patients.first_name'
          : /users/.test(action.gridType)
          ? 'users.last_name,users.first_name'
          : /physicians/.test(action.gridType)
          ? "physicians.name->>'last',physicians.name->>'first'"
          : /episodes/.test(action.gridType)
          ? '-episodes.priority'
          : /inquiries/.test(action.gridType)
          ? '-last_worked_on'
          : /forms/.test(action.gridType)
          ? 'step'
          : /conversations/.test(action.gridType)
          ? 'conversations.last_human_message_sent_at'
          : /rollups/.test(action.gridType)
          ? 'rollups.released_on'
          : nullState.sortParam,
        loading: true,
      };
    default:
      return state;
  }
};
