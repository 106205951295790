import * as ActionCable from '@rails/actioncable';

/**
  An abstract class to store the cable connection and simplify
  the process of interacting with ActionCable.

  @example
  // retreive an existing subscription
  Cable.subscription({
    channel: 'MyChannel',
    params,
  });
*/

export default class Cable {
  static consumer = null;
  static subscriptions = [];

  static isConnected = () => {
    return Cable.consumer && !Cable.consumer.connection.disconnected;
  };

  static connect = (wsUrl) => {
    Cable.consumer = Cable.consumer || ActionCable.createConsumer(wsUrl);
  };

  static disconnect = () => {
    if (!Cable.consumer) return;

    Cable.consumer.connection.monitor.stop();
    Cable.consumer.connection.close({ allowReconnect: false });

    Cable.consumer = null;
  };

  static subscribe = (params, listeners) => {
    if (!Cable.consumer) return;

    Cable.consumer.subscriptions.create(params, listeners);
  };

  static unsubscribe = (params) => {
    const subscription = Cable.subscription(params);

    if (!subscription) return;

    Cable.consumer.subscriptions.remove(subscription);
  };

  static subscription = (params) => {
    if (!Cable.consumer || !Cable.consumer.subscriptions) return;

    return Cable.consumer.subscriptions.subscriptions.find((subscription) => {
      return subscription.identifier === JSON.stringify(params);
    });
  };
}

/**
 * Extend the default behavior of ActionCable to support sending a `pong`
 * request in response to a `ping` message.
 */
if (!ActionCable.ConnectionMonitor.prototype.pong) {
  // Alias the existing function so it can be extended.
  ActionCable.ConnectionMonitor.prototype.defaultRecordPing =
    ActionCable.ConnectionMonitor.prototype.recordPing;

  // Emit a `pong` command to the server
  ActionCable.ConnectionMonitor.prototype.pong = function () {
    this.connection.webSocket.send(JSON.stringify({ command: 'pong' }));
  };

  // Extend the default `recordPing()` function to emit `pong` command.
  ActionCable.ConnectionMonitor.prototype.recordPing = function () {
    this.pong();
    return this.defaultRecordPing();
  };
}
