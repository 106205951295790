import React from 'react';
import { Box } from 'grommet';
import styled, { createGlobalStyle } from 'styled-components';
import theme from 'app/theme';

const { ['primary-blue']: primaryBlue } = theme.global.colors;

import LayoutStyles from './_layout.styles';
import StateStyles from './_state.styles';

export const AppBox = styled(Box)`
  margin-top: ${({ noHeader }) => (noHeader ? 0 : 62)}px;

  @media only print {
    margin-top: 0 !important;
  }
`;

export const AppFullWidthBox = styled(Box)`
  margin-left: 4.5rem;
  position: relative;

  @media print {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const AppLoginBox = styled(Box)`
  align-items: flex-start;
  background: #457fca; /* fallback for old browsers */
  background: linear-gradient(to right, #5691c8, #457fca);
  display: flex;
  height: 100vh !important;
  justify-content: center;
  max-width: 999999px !important;
  width: 100vw !important;
`;

/**
 * Global Style sheet that sits adjacent to the app
 * @example
 * <>
 *   <GlobalStyle />
 *   <App/>
 * </>
 */
const GlobalStyle = createGlobalStyle`
  .grommetux-toast__container {
    z-index: 999999;
  }

  .circle-icon {
    background-color: ${primaryBlue};
    border-radius: 50%;
    padding: 10px;
  }

  ${LayoutStyles}
  ${StateStyles}
`;

/**
 * Provide the global stylesheet to the passed component.
 *
 * @param {node} Component A React component
 * @returns The passed React component, composed alongside the global stylesheet
 */
export const withGlobalStyles = (Component) => {
  const WithGLobalStyles = (props) => {
    const CompWithStyles = (
      <>
        <GlobalStyle />
        <Component {...props} />
      </>
    );

    return CompWithStyles;
  };

  return WithGLobalStyles;
};
