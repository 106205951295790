/**
 * Extracts the user's information for events
 * logged in Sentry.
 *
 * @param {object} state - the redux state of the app.
 * @return {object} an object describing the current user.
 */
export const getUserContext = (state) => {
  const {
    session: { currentUser: { id, email } = {} },
  } = state;

  return { id, email };
};

/**
 * Transforms the state before sending it to Sentry
 * by filtering out sensitive params.
 *
 * @param {object} state - the redux state of the app.
 * @return {object} the state with whitelisted keys.
 */
export const stateTransformer = (state) => {
  const {
    grid: {
      currentPage,
      filters,
      formShown,
      loading,
      lastPage,
      nextPage,
      pageSize,
      prevPage,
      sortParam,
      statusParam,
      tableColumns,
      type,
    },
    session: { error, sessionLoading },
    ui,
  } = state;

  return {
    grid: {
      currentPage,
      filters,
      formShown,
      loading,
      lastPage,
      nextPage,
      pageSize,
      prevPage,
      sortParam,
      statusParam,
      tableColumns,
      type,
    },
    session: {
      error,
      sessionLoading,
    },
    ui,
  };
};
