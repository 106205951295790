"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TestID = void 0;
/**
 * Common components - the keys here should match the name of the component in
 * the `app/components/Common/` directory.
 */
var Components = {
    Avatar: 'Avatar',
    DataGrid: 'DataGrid',
};
/**
 * Top level pages - the keys here should match the name of the component in
 * the `app/components/` directory.
 */
var Pages = {
    Client: 'Client',
    Clients: 'Clients',
    ClientPlan: 'ClientPlan',
    Documents: 'Documents',
    Episode: 'Episode',
    EpisodeCase: 'EpisodeCase',
    EpisodeProgress: 'EpisodeProgress',
    Escalations: 'Escalations',
    Form: 'Form',
    Forms: 'Forms',
    Notes: 'Notes',
    Patient: 'Patient',
    Patients: 'Patients',
    Travel: 'Travel',
    Users: 'Users',
};
/**
 * Naming conventions:
 *
 * 1. Add a new key to the TestID object matching the name of the component
 *    (eg., "Patients" is used to for components in `app/components/Patients`)
 * 2. Use a top level "Page" or "Component" key to map to the top-level
 *    component id (eg., Patients.Page: "Patients").
 * 3. Add nested keys with semantic names, and map them to strings with a format
 *    that includes the component name preceded by two dashes
 *    (eg., "Patients--continue-button").
 */
exports.TestID = (_a = {},
    /**
     * Components' TestIds
     */
    _a[Components.Avatar] = {
        Component: Components.Avatar,
    },
    _a[Components.DataGrid] = {
        Component: Components.DataGrid,
        NumericFilter: "".concat(Components.DataGrid, "--numeric-filter"),
        ToolBarEditButton: "".concat(Components.DataGrid, "--toolbar-edit-button"),
    },
    /**
     * Pages' TestIds
     */
    _a[Pages.Client] = {
        Page: Pages.Client,
        NameInput: "".concat(Pages.Client, "--name-input"),
        NameLabel: "".concat(Pages.Client, "--name-label"),
        EditBar: "".concat(Pages.Client, "--edit-bar"),
        Account: "".concat(Pages.Client, "--account-section"),
        Details: "".concat(Pages.Client, "--details-section"),
        AccountManagerSelect: "".concat(Pages.Client, "--account-manager-select"),
        AccountTierSelect: "".concat(Pages.Client, "--account-tier-select"),
        AccountDeductibleTypeSelect: "".concat(Pages.Client, "--account-deductible-type-select"),
        BypassSsnCheckbox: "".concat(Pages.Client, "--bypass-ssn-checkbox"),
        ClientTypeSelect: "".concat(Pages.Client, "--client-type-select"),
        ContractSignedDateInput: "".concat(Pages.Client, "--contract-signed-date-input"),
        GoLiveDateInput: "".concat(Pages.Client, "--go-live-date-input"),
        PlatformTwoCheckbox: "".concat(Pages.Client, "--platform-two-checkbox"),
        PlatformFee: "".concat(Pages.Client, "--platform-fee"),
        PlatformFeeType: "".concat(Pages.Client, "--platform-fee-type"),
        BillingType: "".concat(Pages.Client, "--billing-type"),
        BillingSchedule: "".concat(Pages.Client, "--billing-schedule"),
        PlatformTwoGoLiveDateInput: "".concat(Pages.Client, "--platform-2-go-live-date"),
        CarrumLocalMinDistanceInput: "".concat(Pages.Client, "--carrum-local-min-input"),
        CarrumLocalSelfSelectionCheckBox: "".concat(Pages.Client, "--carrum-local-self-selection"),
        AddressSection: "".concat(Pages.Client, "--address-section"),
        AddressForm: "".concat(Pages.Client, "--address"),
        AddressFormStreetInput: "".concat(Pages.Client, "--address--street"),
        BundleManagement: "".concat(Pages.Client, "--bundle-management"),
        Tabs: "".concat(Pages.Client, "--tabs"),
        ProfileTab: "".concat(Pages.Client, "--profile-tab"),
        PlanTab: "".concat(Pages.Client, "--plan-tab"),
        PartnershipsTab: "".concat(Pages.Client, "--partnerships-tab"),
        ReferralPartnersTab: "".concat(Pages.Client, "--referral-partners-tab"),
        TravelTab: "".concat(Pages.Client, "--travel-tab"),
        ContactsTab: "".concat(Pages.Client, "--contacts-tab"),
        BundleManagementTab: "".concat(Pages.Client, "--bundle-management-tab"),
        MandatedProceduresTab: "".concat(Pages.Client, "--mandated-procedures-tab"),
        DashboardTab: "".concat(Pages.Client, "--dashboard-tab"),
        EligibilityTab: "".concat(Pages.Client, "--eligibility-tab"),
        ContentTab: "".concat(Pages.Client, "--content-tab"),
        NotesTab: "".concat(Pages.Client, "--notes-tab"),
        ComplianceTab: "".concat(Pages.Client, "--compliance-tab"),
        BundlesDefaultContainer: "".concat(Pages.Client, "--bundles-default-container"),
        ProductOfferingSelect: "".concat(Pages.Client, "--product-offering-select"),
        SearchInput: "".concat(Pages.Client, "--search-input"),
        HospitalFilterContainer: "".concat(Pages.Client, "--hospital-filter-container"),
        HospitalCheckbox: "".concat(Pages.Client, "--hospital-checkbox"),
        MandatedProceduresDisplay: "".concat(Pages.Client, "--mandated-procedures-display"),
        MandatedProceduresEditor: "".concat(Pages.Client, "--mandated-procedures-editor"),
    },
    _a[Pages.Clients] = {
        DataGridContainer: "DataGrid--clients",
    },
    _a[Pages.ClientPlan] = {
        PlanDetailsHeader: "".concat(Pages.ClientPlan, "--plan-details-header"),
        InsurancePlansHeader: "".concat(Pages.ClientPlan, "--insurance-plans-header"),
        InsurancePlansContainer: "".concat(Pages.ClientPlan, "--insurance-plans-container"),
        InsurerNameLabel: "".concat(Pages.ClientPlan, "--insurer-name-label"),
        PlanYearLabel: "".concat(Pages.ClientPlan, "--plan-year-label"),
        CoinsuranceNameLabel: "".concat(Pages.ClientPlan, "--coinsurance-name-label"),
        CoinsuranceOverrideLabel: "".concat(Pages.ClientPlan, "--coinsurance-override-label"),
        CopayPlanNameLabel: "".concat(Pages.ClientPlan, "--copay-plan-name-label"),
        CopayAmountLabel: "".concat(Pages.ClientPlan, "--copay-amount-label"),
        DeductibleNameLabel: "".concat(Pages.ClientPlan, "--deductible-name-label"),
        OverridePlanNameLabel: "".concat(Pages.ClientPlan, "--override-plan-name-label"),
        OverrideFamilyLabel: "".concat(Pages.ClientPlan, "--override-family-label"),
        OverrideIndividualLabel: "".concat(Pages.ClientPlan, "--override-individual-label"),
        AddPlanButton: "".concat(Pages.ClientPlan, "--add-plan-button"),
        InsurerSelect: "".concat(Pages.ClientPlan, "--insurer-select"),
        PlanYearInput: "".concat(Pages.ClientPlan, "--plan-year-input"),
        FormTrash: "".concat(Pages.ClientPlan, "--form-trash"),
        CoinsuranceSelect: "".concat(Pages.ClientPlan, "--coinsurance-select"),
        CoinsuranceOverrideInput: "".concat(Pages.ClientPlan, "--coinsurance-override-input"),
        DeductibleSelect: "".concat(Pages.ClientPlan, "--deductible-select"),
        AddCopayButton: "".concat(Pages.ClientPlan, "--add-copay-button"),
        CopayPlanNameInput: "".concat(Pages.ClientPlan, "--copay-plan-name-input"),
        CopayAmountInput: "".concat(Pages.ClientPlan, "--copay-amount-input"),
        CopayFormTrash: "".concat(Pages.ClientPlan, "--copay-trash-form"),
        AddOverrideButton: "".concat(Pages.ClientPlan, "--add-override-button"),
        OverridePlanNameInput: "".concat(Pages.ClientPlan, "--override-plan-name-input"),
        OverrideFamilyInput: "".concat(Pages.ClientPlan, "--override-family-input"),
        OverrideIndividualInput: "".concat(Pages.ClientPlan, "--override-individual-input"),
        OverrideFormTrash: "".concat(Pages.ClientPlan, "--override-form-trash"),
    },
    _a[Pages.Episode] = {
        Page: Pages.Episode,
        EpisodeHeading: "".concat(Pages.Episode, "--episode-heading"),
        EpisodeTabs: "".concat(Pages.Episode, "--episode-tabs"),
        EpisodePreferredNameInput: "".concat(Pages.Episode, "--preferred-name-input"),
    },
    _a[Pages.EpisodeCase] = {
        Container: "".concat(Pages.EpisodeCase, "--container"),
        AccountsContainer: "".concat(Pages.EpisodeCase, "--accounts-container"),
        DetailsContainer: "".concat(Pages.EpisodeCase, "--details-container"),
        EpisodeProcedure: "".concat(Pages.EpisodeCase, "--episode-procedure"),
        EpisodeProvider: "".concat(Pages.EpisodeCase, "--episode-provider"),
        EpisodePhysician: "".concat(Pages.EpisodeCase, "--episode-physician"),
        ProcedureSelect: "".concat(Pages.EpisodeCase, "--procedure-select"),
        PhysicianSelect: "".concat(Pages.EpisodeCase, "--physician-select"),
        ProviderSelect: "".concat(Pages.EpisodeCase, "--provider-select"),
        PatientDetails: "".concat(Pages.EpisodeCase, "--patient-details"),
        ProfileButton: "".concat(Pages.EpisodeCase, "--profile-button"),
        PatientSummary: "".concat(Pages.EpisodeCase, "--patient-summary"),
    },
    _a[Pages.EpisodeProgress] = {
        BillingCodeDisplay: "".concat(Pages.EpisodeProgress, "--billing-code-display"),
        BillingCodeEditor: "".concat(Pages.EpisodeProgress, "--billing-code-editor"),
        BillingContainer: "".concat(Pages.EpisodeProgress, "--billing-container"),
        Container: "".concat(Pages.EpisodeProgress, "--container"),
        CostEstimateDisplay: "".concat(Pages.EpisodeProgress, "--cost-estimate-display"),
        CostEstimateManualEditor: "".concat(Pages.EpisodeProgress, "--cost-estimate-manual-editor"),
        CostEstimateManualEntryButton: "".concat(Pages.EpisodeProgress, "--cost-estimate-manual-entry-button"),
        CostEstimateRefreshButton: "".concat(Pages.EpisodeProgress, "--cost-estimate-refresh-button"),
        CostEstimateDate: "".concat(Pages.EpisodeProgress, "--cost-estimate-date"),
        CostEstimateCoinsuranceValue: "".concat(Pages.EpisodeProgress, "--cost-estimate-coinsurance-value"),
        CostEstimateCopayValue: "".concat(Pages.EpisodeProgress, "--cost-estimate-copay-value"),
        CostEstimateDeductibleValue: "".concat(Pages.EpisodeProgress, "--cost-estimate-deductible-value"),
        CostEstimateTravelValue: "".concat(Pages.EpisodeProgress, "--cost-estimate-travel-value"),
        CostEstimateTotalValue: "".concat(Pages.EpisodeProgress, "--cost-estimate-total-value"),
        DatesDisplay: "".concat(Pages.EpisodeProgress, "--dates-display"),
        DatesEditor: "".concat(Pages.EpisodeProgress, "--dates-editor"),
        FollowUpDateInput: "".concat(Pages.EpisodeProgress, "--follow-up-date-input"),
        InquiryDisplay: "".concat(Pages.EpisodeProgress, "--inquiry-display"),
        InquiryEditor: "".concat(Pages.EpisodeProgress, "--inquiry-editor"),
        InvoiceDisplay: "".concat(Pages.EpisodeProgress, "--invoice-display"),
        InvoiceEditor: "".concat(Pages.EpisodeProgress, "--invoice-editor"),
        InvoiceIDInput: "".concat(Pages.EpisodeProgress, "--invoice-id-input"),
        InvoiceFinalAmountInput: "".concat(Pages.EpisodeProgress, "--invoice-final-amount-input"),
        InvoicePaidOnDate: "".concat(Pages.EpisodeProgress, "--invoice-paid-on-date"),
        NewTaskEditor: "".concat(Pages.EpisodeProgress, "--new-task-editor"),
        StatusClosureReasonSelect: "".concat(Pages.EpisodeProgress, "--status-closure-reason-select"),
        StatusClosureTypeSelect: "".concat(Pages.EpisodeProgress, "--status-closure-type-select"),
        StatusContainer: "".concat(Pages.EpisodeProgress, "--status-container"),
        StatusDisplay: "".concat(Pages.EpisodeProgress, "--status-display"),
        StatusEditor: "".concat(Pages.EpisodeProgress, "--status-editor"),
        StatusSelect: "".concat(Pages.EpisodeProgress, "--status-select"),
        StepsContainer: "".concat(Pages.EpisodeProgress, "--steps-container"),
        StepTasksEditor: "".concat(Pages.EpisodeProgress, "--step-tasks-editor"),
        AddTaskButton: "".concat(Pages.EpisodeProgress, "--add-task-button"),
        DeleteTaskButton: "".concat(Pages.EpisodeProgress, "--delete-task-button"),
        NewTaskTitleField: "".concat(Pages.EpisodeProgress, "--new-task-title-field"),
        TaskCheckbox: "".concat(Pages.EpisodeProgress, "--task-checkbox"),
    },
    _a[Pages.Form] = {
        Page: Pages.Form,
        AddQuestionButton: "".concat(Pages.Form, "--add-question-button"),
        Container: "".concat(Pages.Form, "--container"),
        Questions: "".concat(Pages.Form, "--questions"),
        QuestionInput: "".concat(Pages.Form, "--question-input"),
        QuestionsTab: "".concat(Pages.Form, "--questions-tab"),
        LayoutTab: "".concat(Pages.Form, "--layout-tab"),
        ResponseTypeSelect: "".concat(Pages.Form, "--response-type-select"),
        SummaryTab: "".concat(Pages.Form, "--summary-tab"),
        TrashButton: "".concat(Pages.Form, "--trash-button"),
    },
    _a[Pages.Forms] = {
        DataGridContainer: "DataGrid--forms",
        FormNameInput: "".concat(Pages.Forms, "--form-name-input"),
        FormStepSelect: "".concat(Pages.Forms, "--form-step-select"),
    },
    _a[Pages.Notes] = {
        AddNoteButton: "".concat(Pages.Notes, "--add-button"),
        AddReminderButton: "".concat(Pages.Notes, "--add-reminder-button"),
        NoteBox: "".concat(Pages.Notes, "--notebox"),
        NoteSubmitButton: "".concat(Pages.Notes, "--submit-button"),
        NotesTab: "".concat(Pages.Notes, "--notes-tab"),
        NoteTextArea: "".concat(Pages.Notes, "--textarea"),
    },
    _a[Pages.Escalations] = {
        AddEscalationButton: "".concat(Pages.Escalations, "--add-button"),
        Description: "".concat(Pages.Escalations, "--description"),
        EscalationsTab: "".concat(Pages.Escalations, "--escalations-tab"),
        EscalationSubmitButton: "".concat(Pages.Escalations, "--submit-button"),
        EscalationTextArea: "".concat(Pages.Escalations, "--textarea"),
        EscalationType: "".concat(Pages.Escalations, "--select"),
    },
    _a[Pages.Documents] = {
        Container: "".concat(Pages.Documents, "--container"),
        DocumentsTab: "".concat(Pages.Documents, "--documents-tab"),
        Logo: "".concat(Pages.Documents, "--logo"),
        PrintButton: "".concat(Pages.Documents, "--print-button"),
    },
    _a[Pages.Patient] = {
        Page: Pages.Patient,
        // Tabs
        ProfileTab: "".concat(Pages.Patient, "--profile-tab"),
        InsuranceTab: "".concat(Pages.Patient, "--insurance-tab"),
        EpisodesTab: "".concat(Pages.Patient, "--episodes-tab"),
        DocumentsTab: "".concat(Pages.Patient, "--documents-tab"),
        MessagesTab: "".concat(Pages.Patient, "--messages-tab"),
        NotesTab: "".concat(Pages.Patient, "--notes-tab"),
        EscalationsTab: "".concat(Pages.Patient, "--escalations-tab"),
        ComplianceTab: "".concat(Pages.Patient, "--compliancee-tab"),
        // Account Section
        Account: "".concat(Pages.Patient, "--account-section"),
        PatientEmail: "".concat(Pages.Patient, "--account-patient-email"),
        RegisteredOn: "".concat(Pages.Patient, "--account-registered-on"),
        ActivatedOn: "".concat(Pages.Patient, "--account-activated-on"),
        AcceptedTermsOn: "".concat(Pages.Patient, "--account-accepted-terms-on"),
        Employer: "".concat(Pages.Patient, "--account-employer"),
        Dependents1: "".concat(Pages.Patient, "--account-dependents-first"),
        Dependents2: "".concat(Pages.Patient, "--account-dependents-second"),
        // Details Section
        Details: "".concat(Pages.Patient, "--details-section"),
        PreferredName: "".concat(Pages.Patient, "--details-preferred-name"),
        PreferredNameInput: "".concat(Pages.Patient, "--details-preferred-name-input"),
        DateOfBirth: "".concat(Pages.Patient, "--details-date-of-birth"),
        DateOfBirthInput: "".concat(Pages.Patient, "--details-date-of-birth-input"),
        MemberSsn: "".concat(Pages.Patient, "--details-member-ssn"),
        MemberSsnInput: "".concat(Pages.Patient, "--details-member-ssn-input"),
        SubscriberSsn: "".concat(Pages.Patient, "--details-subscriber-ssn"),
        SubscriberSsnInput: "".concat(Pages.Patient, "--details-subscriber-ssn-input"),
        PhoneNumber: "".concat(Pages.Patient, "--details-phone-number"),
        PhoneNumberInput: "".concat(Pages.Patient, "--details-phone-number-input"),
        VoicemailsAllowed: "".concat(Pages.Patient, "--details-voicemails-allowed-checkbox"),
        VoicemailsAllowedInput: "".concat(Pages.Patient, "--details-voicemails-allowed-checkbox-input"),
        Language: "".concat(Pages.Patient, "--details-language"),
        LanguageInput: "".concat(Pages.Patient, "--details-language-input"),
        InterpreterRequired: "".concat(Pages.Patient, "--details-interpreter-required"),
        InterpreterRequiredInput: "".concat(Pages.Patient, "--details-interpreter-required-input"),
        Gender: "".concat(Pages.Patient, "--details-gender-dropdown"),
        GenderInput: "".concat(Pages.Patient, "--details-gender-dropdown-input"),
        Ethnicity: "".concat(Pages.Patient, "--details-ethnicity-dropdown"),
        EthnicityInput: "".concat(Pages.Patient, "--details-ethnicity-dropdown-input"),
        MaritalStatus: "".concat(Pages.Patient, "--details-marital-status-dropdown"),
        MaritalStatusInput: "".concat(Pages.Patient, "--details-marital-status-dropdown-input"),
        Religion: "".concat(Pages.Patient, "--details-religion-dropdown"),
        ReligionInput: "".concat(Pages.Patient, "--details-religion-dropdown-input"),
        Height: "".concat(Pages.Patient, "--details-height"),
        HeightFeetInput: "".concat(Pages.Patient, "--details-height-feet-input"),
        HeightInchesInput: "".concat(Pages.Patient, "--details-height-inches-input"),
        Weight: "".concat(Pages.Patient, "--details-weight"),
        WeightInput: "".concat(Pages.Patient, "--details-weight-input"),
        SmokingStatus: "".concat(Pages.Patient, "--details-smoking-status"),
        SmokingStatusInput: "".concat(Pages.Patient, "--details-smoking-status-input"),
        HasChronicPain: "".concat(Pages.Patient, "--details-has-chronic-pain"),
        HasChronicPainInput: "".concat(Pages.Patient, "--details-has-chronic-pain-input"),
        RecommendedForProcedure: "".concat(Pages.Patient, "--details-recommended-for-procedure"),
        RecommendedForProcedureInput: "".concat(Pages.Patient, "--details-recommended-for-procedure-input"),
        PhysicalAddress: "".concat(Pages.Patient, "--details-physical-address"),
        PhysicalAddressStreet: "".concat(Pages.Patient, "--details-physical-address-street"),
        PhysicalAddressStreetInput: "".concat(Pages.Patient, "--details-physical-address-street-input"),
        PhysicalAddressUnit: "".concat(Pages.Patient, "--details-physical-address-unit"),
        PhysicalAddressUnitInput: "".concat(Pages.Patient, "--details-physical-address-unit-input"),
        PhysicalAddressCity: "".concat(Pages.Patient, "--details-physical-address-city"),
        PhysicalAddressCityInput: "".concat(Pages.Patient, "--details-physical-address-city-input"),
        PhysicalAddressState: "".concat(Pages.Patient, "--details-physical-address-state"),
        PhysicalAddressStateInput: "".concat(Pages.Patient, "--details-physical-address-state-input"),
        PhysicalAddressZip: "".concat(Pages.Patient, "--details-physical-address-zip"),
        PhysicalAddressZipInput: "".concat(Pages.Patient, "--details-physical-address-zip-input"),
        MailingAddress: "".concat(Pages.Patient, "--details-mailing-address"),
        MailingAddressStreet: "".concat(Pages.Patient, "--details-mailing-address-street"),
        MailingAddressStreetInput: "".concat(Pages.Patient, "--details-mailing-address-street-input"),
        MailingAddressUnit: "".concat(Pages.Patient, "--details-mailing-address-unit"),
        MailingAddressUnitInput: "".concat(Pages.Patient, "--details-mailing-address-unit-input"),
        MailingAddressCity: "".concat(Pages.Patient, "--details-mailing-address-city"),
        MailingAddressCityInput: "".concat(Pages.Patient, "--details-mailing-address-city-input"),
        MailingAddressState: "".concat(Pages.Patient, "--details-mailing-address-state"),
        MailingAddressStateInput: "".concat(Pages.Patient, "--details-mailing-address-state-input"),
        MailingAddressZip: "".concat(Pages.Patient, "--details-mailing-address-zip"),
        MailingAddressZipInput: "".concat(Pages.Patient, "--details-mailing-address-zip-input"),
        // Episodes Tab
        CreateEpisodeButton: "".concat(Pages.Patient, "--create-episode-button"),
        ProcedureSelect: "".concat(Pages.Patient, "--procedure-select"),
        COESelect: "".concat(Pages.Patient, "--coe-select"),
        PhysicianSelect: "".concat(Pages.Patient, "--physician-select"),
        CreateRecordFormSubmitButton: "CreateRecordForm--submit-button",
        EpisodesList: "".concat(Pages.Patient, "--patient-episodes-list"),
    },
    _a[Pages.Patients] = {
        Page: Pages.Patients,
        CreatePatientConfirmation: "".concat(Pages.Patients, "--confirmation"),
        CreatePatientFirstNameInput: "".concat(Pages.Patients, "--first-name-input"),
        CreatePatientLastNameInput: "".concat(Pages.Users, "--last-name-input"),
        CreatePatientDateOfBirthDateField: "".concat(Pages.Users, "--date-of-birth-date-field"),
        CreatePatientEmailInput: "".concat(Pages.Users, "--email-input"),
        CreatePatientPhoneNumberInput: "".concat(Pages.Users, "--phone-number-input"),
        CreatePatientGenderSelect: "".concat(Pages.Users, "--gender-select"),
        CreatePatientEmployerSelect: "".concat(Pages.Users, "--employer-select"),
        CreatePatientInsurerSelect: "".concat(Pages.Users, "--insurer-select"),
        CreatePatientGroupNumberInput: "".concat(Pages.Users, "--group-number-input"),
        CreatePatientMemberIDInput: "".concat(Pages.Users, "--member-id-input"),
        CreatePatientMemberSSNInput: "".concat(Pages.Users, "--member-ssn-input"),
        CreatePatientSubscriberSSNInput: "".concat(Pages.Users, "--subscriber-ssn-input"),
        CreatePatientPlanTypeSelect: "".concat(Pages.Users, "--plan-type-select"),
        CreatePatientMemberTypeSelect: "".concat(Pages.Users, "--member-type-select"),
        CreatePatientStreetInput: "".concat(Pages.Users, "--street-input"),
        CreatePatientUnitInput: "".concat(Pages.Users, "--unit-input"),
        CreatePatientCityInput: "".concat(Pages.Users, "--city-input"),
        CreatePatientStateInput: "".concat(Pages.Users, "--state-input"),
        CreatePatientPostalCodeInput: "".concat(Pages.Users, "--postal-code-input"),
    },
    _a[Pages.Travel] = {
        ExpenseWorksheet: "".concat(Pages.Travel, "--expense-worksheet"),
        StipendButton: "".concat(Pages.Travel, "--stipend-button"),
        TravelTab: "".concat(Pages.Travel, "--travel-tab"),
    },
    _a[Pages.Users] = {
        DataGridContainer: "DataGrid--users",
        CreateUserFirstNameInput: "".concat(Pages.Users, "--first-name-input"),
        CreateUserLastNameInput: "".concat(Pages.Users, "--last-name-input"),
        CreateUserSSNInput: "".concat(Pages.Users, "--ssn-input"),
        CreateUserEmailInput: "".concat(Pages.Users, "--email-input"),
        CreateUserReferralSourceSelect: "".concat(Pages.Users, "--referral-source-select"),
        CreateUserDateOfBirthDateField: "".concat(Pages.Users, "--date-of-birth-date-field"),
        CreateUserPhoneNumberInput: "".concat(Pages.Users, "--phone-number-input"),
        CreateUserRoleSelect: "".concat(Pages.Users, "--roles-select"),
    },
    _a);
