import { formatData } from 'app/utils/reducerUtils';

export const nullState = Object.freeze({
  patient: null,
  loading: false,
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_PATIENT': {
      const patient = formatData(action.patient.data);

      if (state.patient && state.patient.id === patient.id) {
        if (!patient.dependents && state.patient.dependents)
          patient.dependents = [...state.patient.dependents];
        if (!patient.subscriber && state.patient.subscriber)
          patient.subscriber = { ...state.patient.subscriber };
        if (!patient.user && state.patient.user)
          patient.user = { ...state.patient.user };
      }

      return { ...state, patient, loading: false };
    }
    case 'RECEIVE_PATIENT_EPISODES': {
      const episodes = action.episodes.data.map(formatData);
      const patient = { ...state.patient, episodes };

      return { ...state, patient };
    }
    case 'RECEIVE_PATIENT_LOADING': {
      return { ...state, loading: action.loading };
    }
    default:
      return state;
  }
};
