import React from 'react';
import LoginForm from './LoginForm';
import SmsForm from './SmsForm';
import { Route, Switch } from 'react-router-dom';

const Login = (props) => {
  // NOTE: This subrouter is an abstraction which supports the injection of this
  // component tree into pages and modals.
  return (
    <Switch>
      <Route path="/login/sms">
        <SmsForm {...props} />
      </Route>
      <Route>
        <LoginForm {...props} />
      </Route>
    </Switch>
  );
};

export default Login;
