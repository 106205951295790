import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SideMenu from 'app/components/SideMenu';
import { toggleSideMenu } from 'app/actions/uiActions';

const mapStateToProps = ({ episode, ui }) => {
  return {
    episodes: episode.episodes,
    sideMenuOpen: ui.sideMenuOpen,
  };
};

const mapDispatchToProps = (dispatch, { location }) => {
  const pathname = location.pathname;
  return {
    pathname,
    toggleSideMenu: (status) => dispatch(toggleSideMenu(status)),
  };
};

const SideMenuContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenu);

export default withRouter(SideMenuContainer);
