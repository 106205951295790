import { Box } from 'grommet';
import styled from 'styled-components';

export const MenuItemBox = styled(Box)`
  position: relative;
  margin-left: 0.35rem;
  margin-right: 0.15rem;
`;

export const CountBox = styled(Box)`
  background: ${({ badgeColor }) => badgeColor};
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  height: 1.4rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.4rem;
  z-index: 999;
`;

export const Img = styled.img`
  display: ${({ hide }) => (hide ? 'none' : 'inline-block')};
  height: 1rem;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  top: 2.8rem;
  z-index: 99999;
`;
