import React from 'react';

import PropTypes from 'prop-types';

import theme from 'app/theme';

import { OnlineStatusContainer } from './styles';

const {
  global: {
    colors: { ok, yellow, unknown },
  },
} = theme;

const OnlineStatus = ({ status }) => (
  <OnlineStatusContainer
    statusCode={statusColorCode(status)}
    justify="center"
    align="center"
  ></OnlineStatusContainer>
);

const statusColorCode = (status) => {
  if (status === 'online') {
    return ok;
  } else if (status === 'offline') {
    return unknown;
  } else {
    return yellow;
  }
};

OnlineStatus.propTypes = {
  status: PropTypes.string,
};

export default OnlineStatus;
