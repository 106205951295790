import Cable from 'app/utils/cable';

import {
  deleteConversation,
  requestConversationsInBatches,
  requestConversationsByPatient,
  requestUserStatuses,
  receiveUserStatus,
  receiveConversation,
  receiveMessage,
  setConnectionStatus,
} from 'app/actions/messageActions';

import { createUpload } from 'app/actions/uploadActions';

import { unformatObject } from 'app/utils/reducerUtils';

const conversationsChannelId = {
  channel: 'ConversationsChannel',
  broadcast_on_connect: true,
};

/**
 * Dispatches actions to connect to the Message Service
 * cable and subscribes to the necessary channels.
 */
export function connectToMessageService() {
  return async (dispatch) => {
    await dispatch(connect());
    await dispatch(subscribeToUserStatus());
    await dispatch(subscribeToConversations());
    await dispatch(requestUserStatuses());
  };
}

/**
 * Establishes the connection for the
 * Message Service cable.
 *
 * @return {promise} - A promise that resolves after the connection is established.
 */
export function connect() {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    const wsUrl = `${MESSAGE_API_URL}/cable?authorization=${token}`;

    return Promise.resolve(Cable.connect(wsUrl));
  };
}

/**
 * Closes the connection to the Message Service cable
 * and unsubscribes from all channels.
 */
export function disconnectCable() {
  return () => Cable.disconnect();
}

/**
 * Subscribes to the current user's user status channel
 */
export function subscribeToUserStatus() {
  return function (dispatch) {
    return Cable.subscribe(
      { channel: 'UserStatusChannel' },
      {
        connected: () => {
          console.log(`Connected to UserStatus channel`); // eslint-disable-line
          dispatch(setConnectionStatus({ userStatus: true }));
        },
        disconnected: () => {
          dispatch(setConnectionStatus({ userStatus: false }));
        },
        received: (resp) => dispatch(receiveUserStatus(resp.data)),
      }
    );
  };
}

/**
 * Subscribes to the current user's conversation channel
 */
export function subscribeToConversations() {
  return function (dispatch) {
    return Cable.subscribe(conversationsChannelId, {
      connected: () => {
        console.log(`Connected to Conversations channel`); // eslint-disable-line
      },
      disconnected: () => {
        dispatch(setConnectionStatus({ conversations: false }));
      },
      received: (resp) => {
        if (Array.isArray(resp.data)) {
          return dispatch(requestConversationsInBatches(resp.data.sort()));
        }

        if (resp.data._delete) {
          return dispatch(removeConversation(resp.data.id));
        }

        return dispatch(addConversation(resp.data));
      },
    });
  };
}

/**
 * Subscribes to the messages channel for the conversation.
 *
 * @param {object} conversation the conversation to be added
 */
export function addConversation(conversation) {
  return function (dispatch) {
    dispatch(subscribeToMessages(conversation.id));
    dispatch(requestUserStatuses());
    return dispatch(receiveConversation(conversation));
  };
}

/**
 * Unsubscribes from the messages channel for the conversation
 * and removes the conversation from the store.
 *
 * @param {string} conversationId identifier for the conversation
 */
export function removeConversation(conversationId) {
  return function (dispatch) {
    unsubscribeFromMessages(conversationId);
    return dispatch(deleteConversation(conversationId));
  };
}

/**
 * Subscribes to the messages channel for the specified conversation
 *
 * @param {number} conversationId - id for the conversation
 */
export function subscribeToMessages(conversationId) {
  return function (dispatch) {
    const params = {
      channel: 'MessagesChannel',
      conversation_id: conversationId,
    };

    if (Cable.subscription(params)) return;

    return Cable.subscribe(params, {
      connected: () => {
        // eslint-disable-next-line no-console
        console.log(`Connected to Messages channel ${conversationId}`);
      },
      received: (resp) => dispatch(receiveMessage(resp.data)),
    });
  };
}

function unsubscribeFromMessages(conversationId) {
  Cable.unsubscribe({
    channel: 'MessagesChannel',
    conversation_id: conversationId,
  });
}

/**
 * Creates a conversation in the Message Service
 * using the action cable connection.
 *
 * @param {object} conversation - an object with params for creating the conversation.
 */
export function createConversation(conversation) {
  return () => {
    const connection = Cable.subscription(conversationsChannelId);

    return connection.perform('create', { conversation });
  };
}

/**
 * Updates a conversation in the Message Service
 * using the action cable connection.
 *
 * @param {integer} id - the ID of the conversation to update.
 * @param {object} conversation - an object with params for updating the conversation.
 */
export function updateConversation(id, conversation) {
  return () => {
    const connection = Cable.subscription(conversationsChannelId);

    return connection.perform('update', { id, conversation });
  };
}

/**
 * Deletes a conversation in the Message Service
 * using the action cable connection.
 *
 * @param {integer} id - the ID of the conversation to delete.
 */
export function destroyConversation(id) {
  return () => {
    const connection = Cable.subscription(conversationsChannelId);

    return connection.perform('destroy', { id });
  };
}

export function waitForConversations(timeout = 100) {
  return async (dispatch, getState) => {
    if (getState().message.progress !== 100) return Promise.resolve();

    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (getState().message.progress !== 100) return;
        resolve(clearInterval(interval));
      }, timeout);
    });
  };
}

/**
 * Finds a conversation between the given user and a patient (if any).
 *
 * @param {object} user - the user wishing to have a conversation.
 * @param {object} patient - the patient to find a conversation with.
 */
export function findConversation(user, patient) {
  return async (dispatch, getState) => {
    // Wait for pending load functions to complete.
    await dispatch(waitForConversations());

    // Look for a conversation already loaded for this patient.
    const { conversations } = getState().message;
    const conversationInState = conversations.find(({ participants }) =>
      participants.find(({ id }) => id === patient.id)
    );

    if (conversationInState) {
      dispatch(addConversation(conversationInState));
      return conversationInState;
    }

    // Load a conversation for this patient.
    const response = await dispatch(requestConversationsByPatient(patient.id));
    const conversation = response.data.data[0];

    // If a conversation exists, subscribe.
    if (conversation) {
      dispatch(addConversation(conversation));
      return conversation;
    }
  };
}

export function startConversation(user, patient) {
  return (dispatch) => {
    dispatch(
      createConversation({
        participants: [asParticipant(patient, true), asParticipant(user)],
      })
    );
  };
}

export function joinConversation(conversationId, user) {
  return (dispatch, getState) => {
    const { conversations } = getState().message;
    const conversation = conversations.find(
      ({ id }) => parseInt(id) === parseInt(conversationId)
    );

    if (!conversation) return;

    dispatch(
      updateConversation(conversationId, {
        participants: [
          ...conversation.participants.map(unformatObject),
          asParticipant(user),
        ],
      })
    );
  };
}

/**
 * Removes the current user from the
 * conversation in the message service.
 *
 * @param  {string} id identifier for the conversation
 */
export function leaveConversation(id) {
  return (dispatch, getState) => {
    const { message, session } = getState();

    const user = session.currentUser;
    const convo = message.conversations.find((c) => c.id === id);

    const participants = convo.participants.filter((participant) => {
      return participant.id !== user.id;
    });

    dispatch(
      updateConversation(id, { participants: participants.map(unformatObject) })
    );
  };
}

function asParticipant(participant, patient) {
  return {
    id: String(participant.id),
    name: `${participant.firstName} ${participant.lastName}`,
    phone_number: participant.profile?.patientFacingPhoneNumber,
    profile_image: participant.profileImage,
    patient,
  };
}

/**
 * Creates a message in the Message Service
 * using the action cable connection.
 *
 * @param {object} message - the message object
 * @param {number} conversationId - id for the conversation the message belongs to
 */
export function createMessage(message, conversationId) {
  if (!conversationId) return;

  return async (dispatch, getState) => {
    await dispatch(subscribeToMessages(conversationId));

    const connection = Cable.subscription({
      channel: 'MessagesChannel',
      conversation_id: conversationId,
    });

    dispatch(
      receiveMessage({
        ...message,
        attachments: message.previews,
        conversationId: parseInt(conversationId),
        id: Math.random(),
        author: { id: getState().session.currentUser.id },
        readBy: [getState().session.currentUser.id],
        createdAt: new Date(),
        updatedAt: new Date(),
        _pending: Boolean(connection),
        _unsent: !connection,
      })
    );

    if (!connection) return;

    if (message.body && message.body.length) {
      connection.perform('create', { message: { body: message.body } });
    }

    if (message.attachments && message.attachments.length) {
      message.attachments.forEach((attachment) => {
        if (/^http/.test(attachment)) {
          return connection.perform('create', {
            message: { attachments: [attachment] },
          });
        }

        dispatch(createUpload(attachment)).then((result) => {
          connection.perform('create', {
            message: { attachments: [result] },
          });
        });
      });
    }
  };
}

/**
 * Updates a message in the Message Service
 * using the action cable connection.
 *
 * @param {number} id - id for the message to update
 * @param {object} status - the message object
 */
export function updateMessage(id, message) {
  return function () {
    const connection = Cable.subscription({
      channel: 'MessagesChannel',
      conversation_id: message.conversationId.toString(),
    });

    return connection.perform('update', {
      id,
      message,
    });
  };
}
