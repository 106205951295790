import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Box } from 'grommet';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from 'app/theme';

export default class MenuDropdown extends Component {
  static propTypes = {
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,

    children: PropTypes.array,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Closes the dropdown when clicking outside the dropdown ref
   */
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target))
      this.onClose();
  };

  /**
   * Sets a ref for the dropdown element
   */
  setWrapperRef = (node) => (this.wrapperRef = ReactDOM.findDOMNode(node)); // eslint-disable-line

  /**
   * Closes the dropdown
   */
  onClose = () => setTimeout(() => this.props.handleClose(), 150);

  render() {
    const { open, children } = this.props;

    return (
      open && (
        <MenuDropdownBox
          lightGrey={theme.global.colors.light3}
          ref={this.setWrapperRef}
        >
          {children}
        </MenuDropdownBox>
      )
    );
  }
}

const MenuDropdownBox = styled(Box)`
  background: ${({ lightGrey }) => lightGrey};
  border-top: none;
  border: 1px solid ${({ lightGrey }) => lightGrey};
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  max-height: calc(100vh - 7.6rem);
  max-width: none !important;
  position: absolute;
  right: -1.2rem;
  top: 3.8rem;
  width: 28rem;
  z-index: 9999;

  &:hover {
    border-color: darken(${({ lightGrey }) => lightGrey}, 2%) !important;
    border-top-width: 0;
  }
`;
