import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  productOfferings: [],
  loading: false,
});

export default (state = nullState, action) => {
  switch (action.type) {
    case 'RECEIVE_PRODUCT_OFFERINGS': {
      const newProductOfferings = action.productOfferings.map(formatData);
      const productOfferings = action.append
        ? [...state.productOfferings, ...newProductOfferings]
        : newProductOfferings;
      return { ...state, productOfferings };
    }
    case 'RECEIVE_PRODUCT_OFFERING_LOADING':
      return { ...state, loading: action.productOfferingLoading };
    default:
      return state;
  }
};
