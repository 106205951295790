import React, { useState } from 'react';
import { Box, Button, FormField, Image, Layer, Text, TextInput } from 'grommet';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import carrumLogo from 'app/assets/img/carrum_logo.png';

const propTypes = {
  loginUser: PropTypes.func.isRequired,
  error: PropTypes.string,
  modal: PropTypes.bool,
};

const SmsForm = ({
  error = '',
  history,
  modal = false,
  isLoading,
  loginUser,
}) => {
  const {
    state: { email, password },
  } = useLocation();
  const [smsCode, setSmsCode] = useState('');

  const onSubmit = async (event) => {
    event.preventDefault();

    await loginUser(
      {
        email,
        password,
        smsCode,
      },
      history
    );
  };

  const smsForm = (
    <Box
      align="center"
      background="light-1"
      data-testid="Login--sms--sms"
      elevation="small"
      gap="small"
      pad="medium"
      round="xsmall"
      width="medium"
    >
      <Image height="56px" src={carrumLogo} />

      <Text size="small" textAlign="center">
        Please enter the 6-digit code that was sent to your phone
      </Text>

      <Form data-testid="Login--form--sms--sms" onSubmit={onSubmit}>
        <FormField width="full" label="SMS Code">
          <TextInput
            data-testid="Login--sms-code-input"
            a11yTitle="SMS Code"
            name="SMS Code"
            onChange={({ target: { value } }) => setSmsCode(value)}
            value={smsCode}
          />
        </FormField>

        {error?.length > 0 && (
          <Text
            color="status-critical"
            margin={{ top: 'small' }}
            size="small"
            textAlign="center"
          >
            {error}
          </Text>
        )}
        <Button
          data-testid="Login--sms-sms--submit-button"
          disabled={smsCode.length !== 6 || isLoading}
          fill="horizontal"
          label={!isLoading ? 'Log in' : 'Verifying...'}
          margin={{ top: 'small' }}
          primary
          type="submit"
        />
      </Form>
    </Box>
  );

  return modal ? (
    <Layer align="center">{smsForm}</Layer>
  ) : (
    <Box align="center" alignSelf="center" flex justify="center">
      {smsForm}
    </Box>
  );
};

export default SmsForm;

SmsForm.propTypes = propTypes;

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;
