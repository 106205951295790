import camelCase from 'camelcase';
import { isNil } from 'lodash';
import { snakeCase } from 'snake-case';
import { cdnUrl } from '../constants';
import { formatDateRailsToDisplay, formatDateDisplayToRails } from '../methods';

export function replaceAtIndex(array, item, index) {
  return [...array.slice(0, index), item, ...array.slice(index + 1)];
}

export function removeAtIndex(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function addAtBeginning(array, item) {
  return [item, ...array];
}

export function addAtEnd(array, item) {
  return [...array, item];
}

export function formatData(data, type) {
  if (typeof data !== 'object' || data === null) return data;
  if (/^\d+$/.test(type)) type = data.type;

  const formattedData = {};
  const attributes = { ...(data.attributes || data) };
  const timestamps = [
    'createdAt',
    'updatedAt',
    'lastWorkedOn',
    'startedOn',
    'completedOn',
    'startedAt',
    'endedAt',
    'activatedOn',
    'estimatedAt',
    'paidOn',
  ];

  Object.keys(attributes).map((key) => {
    const formattedKey = camelCase(key);
    if (isISODateString(attributes[key])) {
      // format dates from YYYY-MM-DD to MM-DD-YYYY format
      attributes[key] = formatDateRailsToDisplay(attributes[key]);
    } else if (Array.isArray(attributes[key])) {
      attributes[key] = attributes[key].map((item) => formatData(item, key));
    } else if (attributes[key] && timestamps.includes(formattedKey)) {
      attributes[key] = new Date(attributes[key]);
    } else if (attributes[key] && typeof attributes[key] === 'object') {
      attributes[key] = formatData(attributes[key], data.type);
    }

    formattedData[formattedKey] = attributes[key];
  });

  if (data.id) formattedData.id = data.id;

  if (formattedData.images && typeof formattedData.images === 'object') {
    Object.keys(formattedData.images).forEach((imageKey) => {
      formattedData.images[imageKey] = carrumImagePath(
        formattedData.images[imageKey],
        type || data.type
      );
    });
  }

  if (formattedData.profileImage) {
    formattedData.profileImage = carrumImagePath(
      formattedData.profileImage,
      type || data.type
    );
  }

  if (formattedData.videoThumbnail) {
    formattedData.videoThumbnail = carrumImagePath(
      formattedData.videoThumbnail,
      type || data.type
    );
  }

  return formattedData;
}

export function unformatObject(data) {
  if (typeof data !== 'object') {
    return data;
  }

  const unformattedObject = {};

  Object.keys(data).forEach((key) => {
    const unformattedKey = key === '_destroy' ? key : snakeCase(key);
    if (isUSDateString(data[key])) {
      // format dates from MM-DD-YYYY to YYYY-MM-DD format
      unformattedObject[unformattedKey] = formatDateDisplayToRails(data[key]);
    } else if (Array.isArray(data[key])) {
      unformattedObject[unformattedKey] = data[key].map(unformatObject);
    } else if (data[key] instanceof Date) {
      unformattedObject[unformattedKey] = data[key].toISOString();
    } else if (data[key] && typeof data[key] === 'object') {
      unformattedObject[unformattedKey] = unformatObject(data[key]);
    } else {
      unformattedObject[unformattedKey] = data[key];
    }
  });

  return unformattedObject;
}

/**
 * Parses pagination links from a given links object.
 *
 * @param {object} links - an object with links to extract.
 * @return {object} an object with parsed pagination numbers.
 */
export function parsePagination({ prev, next, last, self }) {
  const prevPage = prev && parsePageNumber(prev);
  const nextPage = next && parsePageNumber(next);
  const lastPage = last && parsePageNumber(last);
  let currentPage = self && parsePageNumber(self);
  currentPage = currentPage <= lastPage ? currentPage : lastPage;

  return { prevPage, nextPage, lastPage, currentPage };
}

function isISODateString(value) {
  if (value && value && typeof value === 'string') {
    const seperator = value.match(/\/|-/);
    const dateArray = value.split(seperator);
    return (
      value.length === 10 && dateArray.length === 3 && dateArray[0].length === 4
    );
  }
  return false;
}

function isUSDateString(value) {
  if (value && typeof value === 'string') {
    const seperator = value.match(/\/|-/);
    const dateArray = value.split(seperator);
    return (
      value.length === 10 && dateArray.length === 3 && dateArray[2].length === 4
    );
  }
  return false;
}

/**
 * Extracts the page number from a given link URL.
 *
 * @param {string} link - a URL linking to the previous, next, or last page.
 * @return {integer|null} the page number for the given link (if any).
 */
function parsePageNumber(link) {
  return link.match(/page%5Bnumber%5D=(\d+)/)
    ? parseInt(link.match(/page%5Bnumber%5D=(\d+)/)[1])
    : null;
}

/**
 * Prefixes a URL if it is a relative path.
 *
 * @param {string} path The image path.
 * @param {string} namespace The namespace in the CDN.
 * @return {string} A URL for an image.
 */
export function carrumImagePath(path, namespace) {
  if (!path) return;
  if (!/^(http|data:)/.test(path)) return `${cdnUrl}/${namespace}/${path}`;

  return path;
}

/**
 *
 * @param {object|array} prevValue The non-primitive value that should be merged over.
 * @param {object|array} incomingSource The non-primitive value that should overwrite the previous value.
 * @returns {object|array} The new value as long as the previous value is not nil. Otherwise, the previous value.
 */
export const overwriteNonPrimitive = (prevValue, newValue) => {
  if (!isNil(newValue)) {
    return newValue;
  }
  return prevValue;
};
