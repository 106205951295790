import axios from 'axios';
import sha1 from 'crypto-js/sha1';
import { digest } from 'app/utils/constants';
import { unformatObject } from 'app/utils/reducerUtils';
import {
  formatDateDisplayToRails,
  decryptDigest,
  fetchLocationFromAddress,
} from 'app/utils/methods';

import { receiveSteps } from 'app/actions/stepActions';
import { fetchClient } from 'app/actions/clientActions';
import { fetchInsurer } from 'app/actions/insurerActions';
import { fetchPatient } from 'app/actions/patientActions';
import { showGlobalError } from 'app/actions/uiActions';
import { formatData } from '../../utils/reducerUtils';

export function fetchEligiblePatient(params) {
  const { firstName, lastName, employerRegistrationId } = params;
  let { dateOfBirth } = params;
  dateOfBirth = formatDateDisplayToRails(dateOfBirth);

  // create carrum id
  const salt =
    firstName.toLowerCase() +
    lastName.toLowerCase() +
    dateOfBirth +
    employerRegistrationId;
  const carrumId = sha1(salt + decryptDigest(digest));

  return function (dispatch, getState) {
    return axios.get(
      `${CORE_API_URL}/eligible_patients/${carrumId}/confirmed`,
      {
        headers: {
          Authorization: `Bearer ${getState().session.coreServiceToken}`,
        },
      }
    );
  };
}

export function verifyPatientEligibility(params) {
  return function (dispatch) {
    return dispatch(fetchEligiblePatient(params))
      .then((result) => dispatch(receiveEligiblePatient(result.data.data)))
      .catch(() => dispatch(receiveEligiblePatientNotFound()));
  };
}

export function findUserByCarrumId(carrumId) {
  return function (dispatch, getState) {
    return axios
      .get(`${CORE_API_URL}/users?filter[carrum_id]=${carrumId}&per_page=1`, {
        headers: {
          Authorization: `Bearer ${getState().session.coreServiceToken}`,
        },
      })
      .then((response) => dispatch(receiveEpisodeUser(response.data.data[0])));
  };
}

/**
 * Use the eligible patient's address to set the episode's location using the
 * Google API, then create the episode in the Care Service and redirect to the
 * newly-created episode.
 *
 * @param  {object}  episode  the episode object
 * @param  {object}  history  router object used to navigate when required
 */
export function createEpisode(episode, history) {
  const initialStep = (address, getState) => {
    if (address) {
      return fetchLocationFromAddress(address).then((location) => {
        return axios.post(
          `${CARE_API_URL}/episodes`,
          { episode: unformatObject({ ...episode, location }) },
          { headers: { Authorization: getState().session.coreServiceToken } }
        );
      });
    } else {
      return axios.post(
        `${CARE_API_URL}/episodes`,
        { episode: unformatObject(episode) },
        { headers: { Authorization: getState().session.coreServiceToken } }
      );
    }
  };

  return function (dispatch, getState) {
    dispatch(receiveEpisodeLoading(true));

    const address = episode.eligiblePatient?.address;
    return initialStep(address, getState)
      .then((result) => {
        dispatch(receiveEpisodeLoading(false));
        if (history) history.push(`/episodes/${result.data.data.id}`);
      })
      .catch((error) => {
        dispatch(receiveEpisodeLoading(false));
        return dispatch(showGlobalError(error));
      });
  };
}

/**
 * Makes a PATCH request to update an episode in the API.
 *
 * @param   {number}   id      identifier of episode to update
 * @param   {object}   params  params to update episode with
 *
 * @return  {promise}          promise that resolves after updating
 */
export function updateEpisode(id, params) {
  return async (dispatch, getState) => {
    try {
      if (
        params.location &&
        params.location.address &&
        params.location.address.street &&
        params.location.address.city &&
        params.location.address.state &&
        params.location.address.postalCode
      ) {
        params.location = await fetchLocationFromAddress(
          params.location.address
        );
      }

      if (params.provider) {
        params.productOffering = params.provider.productOffering;
      }

      const response = await axios.patch(
        `${CARE_API_URL}/episodes/${id}`,
        { episode: unformatObject({ ...params }) },
        {
          headers: {
            Authorization: `Bearer ${getState().session.coreServiceToken}`,
          },
        }
      );

      dispatch(receiveEpisode(response.data.data, true));

      return true;
    } catch (error) {
      dispatch(showGlobalError(error));

      return false;
    }
  };
}

/**
 * Fetch an episode from the API, and dispatch several events to populate the
 * redux store.
 *
 * @param   {string}   episodeId  identifier for episode to fetch
 *
 * @return  {promise}             promise that resolves with episode data
 */
export function fetchEpisode(episodeId, params = {}, clientParams = {}) {
  return async (dispatch, getState) => {
    try {
      const {
        episode: { loading },
      } = getState();

      if (loading) return;

      dispatch(receiveEpisodeLoading(true));

      const response = await axios.get(
        `${CARE_API_URL}/episodes/${episodeId}`,
        {
          params: {
            ...params,
            include: 'scheduled_jobs,steps,submissions',
          },
          headers: {
            Authorization: `Bearer ${getState().session.coreServiceToken}`,
          },
        }
      );

      const episode = response.data.data;
      const included = response.data.included;

      if (included) {
        // TODO: Delete this conditional after all steps have been converted to type 'care-steps',
        // then just filter steps with type === 'care-steps'
        if (included.filter((data) => data.type === 'care-steps').length > 0) {
          dispatch(
            receiveSteps(included.filter((data) => data.type === 'care-steps'))
          );
        } else {
          dispatch(
            receiveSteps(included.filter((data) => data.type === 'steps'))
          );
        }

        dispatch(
          receiveSubmissions(
            included.filter((data) => data.type === 'submissions')
          )
        );
      }

      dispatch(receiveEpisode(episode));

      const promises = [
        dispatch(fetchEmployerSubgroups(clientParams)),
        dispatch(fetchEpisodeInsurer()),
      ];

      const { features } = getState().session;
      if (features.includes('patients:view')) {
        promises.push(dispatch(fetchEpisodeEligiblePatient()));
      }

      await Promise.all(promises);

      dispatch(receiveEpisodeLoading(false));
    } catch (error) {
      dispatch(receiveEpisodeLoading(false));
      dispatch(showGlobalError(error));
    }
  };
}

/**
 * Fetch episodes from the API, and dispatch several events to populate the redux store.
 *
 * @param   {object}   params  optional params to pass to API
 *
 * @return  {promise}          promise that resolves with episode data
 */
export function fetchEpisodes(params) {
  return async (dispatch, getState) => {
    const {
      episode: { loading },
    } = getState();

    if (loading) return;

    dispatch(receiveEpisodeLoading(true));

    try {
      const response = await dispatch(requestEpisodes(params));
      dispatch(receiveEpisodes(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    } finally {
      dispatch(receiveEpisodeLoading(false));
    }
  };
}

/**
 * Fetch episodes from the API.
 *
 * @param   {object}   params  optional params to pass to API
 *
 * @return  {promise}          promise that resolves with episode data
 */
export function requestEpisodes(params) {
  return (_, getState) =>
    axios.get(`${CARE_API_URL}/episodes`, {
      params,
      headers: {
        Authorization: `Bearer ${getState().session.coreServiceToken}`,
      },
    });
}

export function fetchUserCurrentEpisode(userId) {
  return async (_, getState) => {
    const resp = await axios.get(`${CARE_API_URL}/episodes`, {
      headers: {
        Authorization: `Bearer ${getState().session.coreServiceToken}`,
      },
      params: {
        'filter[user_id]': userId,
        status: 'active',
        sort: '-created_at',
      },
    });

    if (resp.data.data.length) {
      resp.data.data = resp.data.data[0];
      return resp.data;
    } else {
      throw new Error('Not Found');
    }
  };
}

/**
 * Parse or fetch subgroups for the employer.
 *
 * @return  {promise}  promise that resolves after fetching subgroups
 */
export function fetchEmployerSubgroups(params = {}) {
  return async (dispatch, getState) => {
    const { employer } = getState().episode.episode.eligiblePatient;

    if (employer && employer.subGroups) {
      await dispatch(receiveSubGroups(employer.subGroups));
    } else if (employer && employer.id) {
      await dispatch(fetchSubGroups(employer.id, params));
    }
  };
}

/**
 * Fetch eligible patient record to see if they are still eligible.
 *
 * @return  {promise}  promise that resolves after checking eligibility
 */
export function fetchEpisodeEligiblePatient() {
  return async (dispatch, getState) => {
    const { carrumId } = getState().episode.episode.eligiblePatient;
    await dispatch(fetchPatient(carrumId, { include: 'user' }));

    const { patient } = getState().patient;
    if (!patient) return;

    dispatch(receiveEligiblePatientActive(patient.active));
  };
}

export function fetchEpisodeInsurer() {
  return async (dispatch, getState) => {
    try {
      const { insurance } = getState().episode.episode.eligiblePatient;
      const response =
        insurance && (await dispatch(fetchInsurer(insurance.partnerId)));
      if (!response || !response.data || !response.data.data) return;

      dispatch(receiveEpisodeInsurer(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

/**
 * Fetch subgroups for a specific client.
 *
 * - If client is already present in reducer, return its subgroups.
 * - Otherwise, fetch the client from the API first.
 *
 * @param   {string}   clientId  identifier for client to fetch subgroups for
 *
 * @return  {promise}            promise that resolves with subgroups
 */
export function fetchSubGroups(clientId, params = {}) {
  return (dispatch, getState) => {
    const clients = getState().client.clients || [];

    const client = clients.filter(
      ({ id }) => parseInt(id, 10) === parseInt(clientId, 10)
    )[0];

    if (client && client.subGroups) {
      return Promise.resolve(
        dispatch(receiveSubGroups(Object.values(client.subGroups)))
      );
    }

    return dispatch(fetchClient(clientId, params)).then(() => {
      const { client } = getState().client;
      if (client && client.subGroups) {
        return dispatch(receiveSubGroups(Object.values(client.subGroups)));
      }
    });
  };
}

/**
 * When specified episode exists in the Redux store, fetch the current step and
 * update the episode. Otherwise, fetch the episode.
 *
 * @param   {string}   episodeId  identifier for episode to fetch
 *
 * @return  {promise}             promise that resolves with episode data
 */
export function fetchCurrentStep(episodeId) {
  return (dispatch, getState) => {
    return axios
      .get(`${CARE_API_URL}/episodes/${episodeId}/current_step`, {
        headers: {
          Authorization: `Bearer ${getState().session.coreServiceToken}`,
        },
      })
      .then((result) => dispatch(receiveCurrentStep(result.data.data)));
  };
}

/**
 * Make a GET request to the Price Service Cost Estimate API to fetch a
 * patient's estimated out-of-pocket cost for a given procedure.
 *
 * @param   {integer}  episodeId  primary key of episode
 *
 * @return  {promise}             promise that resolves after fetching estimate
 */
export function fetchCostEstimate(episodeId) {
  return async (dispatch, getState) => {
    try {
      const { coreServiceToken, features } = getState().session;

      if (features.includes('demo')) {
        return {
          coinsurance: 0,
          copay: 0,
          deductible: 0,
          estimatedAt: new Date(),
          finalAmount: null,
          paid: false,
          savings: 4250,
          travel: 0,
        };
      }

      const response = await axios.get(
        `${PRICE_API_URL}/cost_estimates/${episodeId}`,
        {
          headers: { Authorization: `Bearer ${coreServiceToken}` },
        }
      );

      const { attributes } =
        (response && response.data && response.data.data) || {};

      return {
        coinsurance: attributes['carrum-coinsurance-amount'],
        copay: attributes['carrum-copay'],
        deductible: attributes['carrum-deductible'],
        estimatedAt: new Date(),
        savings:
          attributes['non-carrum-coinsurance-amount'] +
          attributes['non-carrum-copay'] +
          attributes['non-carrum-deductible'],
        finalAmount: null,
        paid: false,
        travel: 0,
      };
    } catch (error) {
      dispatch(showGlobalError(error));
      return {};
    }
  };
}

/**
 * Make a POST request to the Price Service Travel Stipend API to fetch a
 * patient's travel stipend for a given Carrum Id and procedure.
 *
 * @param   {integer}  carrumId      Carrum ID of patient
 * @param   {string}   procedureKey  key of procedure to get stipend for
 * @param   {object}   trip          additional details for generating stipend
 *
 * @return  {promise}                promise that resolves after fetching
 *                                   stipend
 */
export function createTravelStipend(carrumId, procedureKey, trip) {
  return async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `${PRICE_API_URL}/travel_stipends/${carrumId}/${procedureKey}`,
        unformatObject({ trip }),
        {
          headers: {
            Authorization: `Bearer ${getState().session.coreServiceToken}`,
          },
        }
      );

      // formatData is used here since this is not dispatched to a reducer.
      const { items } = formatData(response?.data?.data);
      return items;
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

/**
 * Make a PATCH request to the Care Service Submissions API to update a
 * submission and dispatch the result to the reducer.
 *
 * @param   {integer}  episodeId     identifier of episode with the submission
 *                                   to update
 * @param   {integer}  submissionId  identifier of submission to update
 * @param   {object}   submission    params used to update the record
 *
 * @return  {promise}                promise that resolves after updating
 *                                   submission
 */
export function updateEpisodeSubmission(episodeId, submissionId, submission) {
  return (dispatch, getState) => {
    const { coreServiceToken } = getState().session;

    return axios
      .patch(
        `${CARE_API_URL}/episodes/${episodeId}/submissions/${submissionId}`,
        unformatObject({ submission }),
        { headers: { Authorization: `Bearer ${coreServiceToken}` } }
      )
      .then((response) => {
        const submissions = [...(getState().episode.submissions || [])];
        const index = submissions.findIndex(({ id }) => id === submissionId);
        submissions[index] = response.data.data;

        dispatch(receiveSubmissions(submissions));
      })
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Make a DELETE request to the Care Service Submissions API to destroy a
 * submission and dispatch the result to the reducer.
 *
 * @param   {integer}  episodeId     identifier of episode with submission to
 *                                   destroy
 * @param   {integer}  submissionId  identifier of submission to destroy
 *
 * @return  {promise}                promise that resolves after destroying
 *                                   submission
 */
export function destroyEpisodeSubmission(episodeId, submissionId) {
  return (dispatch, getState) => {
    const { coreServiceToken } = getState().session;

    return axios
      .delete(
        `${CARE_API_URL}/episodes/${episodeId}/submissions/${submissionId}`,
        { headers: { Authorization: `Bearer ${coreServiceToken}` } }
      )
      .then(() => {
        const submissions = [...getState().episode.submissions].filter(
          ({ id }) => id !== submissionId
        );

        dispatch(receiveSubmissions(submissions));
      })
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Make a PATCH request to the Care Service Episodes API to update the
 * `oopEstimate` for an episode.
 *
 * @param   {integer}  episodeId     identifier of episode to update
 * @param   {object}   costEstimate  estimate used to update episode
 *
 * @return  {promise}                promise that resolves after updating
 *                                   episode
 */
export function updateEpisodeCostEstimate(episodeId, costEstimate) {
  return (dispatch) => {
    return dispatch(
      updateEpisode(episodeId, {
        oopEstimate: {
          coinsurance: costEstimate.carrumCoinsuranceAmount,
          deductible: costEstimate.carrumDeductible,
          savings:
            costEstimate.nonCarrumCoinsuranceAmount +
            costEstimate.nonCarrumDeductible,
          finalAmount: null,
          paid: false,
          travel: 0,
        },
      })
    );
  };
}

/**
 * Request records from the `/oncology_episode_types`,
 * `/oncology_guidance_types`, and `/oncology_assessment_types` API.
 */
function requestOncologyEpisodeDetails(route) {
  return (_, getState) => {
    return axios.get(`${CORE_API_URL}/${route}`, {
      headers: {
        Authorization: `Bearer ${getState().session.coreServiceToken}`,
      },
    });
  };
}

export function fetchOncologyEpisodeTypes() {
  return async (dispatch) => {
    try {
      dispatch(receiveOncologyEpisodeTypesLoading(true));
      const response = await dispatch(
        requestOncologyEpisodeDetails('oncology_episode_types')
      );
      dispatch(receiveOncologyEpisodeTypes(response.data.data));
      dispatch(receiveOncologyEpisodeTypesLoading(false));
    } catch (error) {
      dispatch(receiveOncologyEpisodeTypesLoading(false));
      dispatch(showGlobalError(error));
    }
  };
}

export function fetchOncologyGuidanceTypes() {
  return async (dispatch) => {
    try {
      dispatch(receiveOncologyGuidanceTypesLoading(true));
      const response = await dispatch(
        requestOncologyEpisodeDetails('oncology_guidance_types')
      );
      dispatch(receiveOncologyGuidanceTypes(response.data.data));
      dispatch(receiveOncologyGuidanceTypesLoading(false));
    } catch (error) {
      dispatch(receiveOncologyGuidanceTypesLoading(false));
      dispatch(showGlobalError(error));
    }
  };
}

export function fetchOncologyTreatmentTypes() {
  return async (dispatch) => {
    try {
      dispatch(receiveOncologyTreatmentTypesLoading(true));
      const response = await dispatch(
        requestOncologyEpisodeDetails('oncology_treatment_types')
      );
      dispatch(receiveOncologyTreatmentTypes(response.data.data));
      dispatch(receiveOncologyTreatmentTypesLoading(false));
    } catch (error) {
      dispatch(receiveOncologyTreatmentTypesLoading(false));
      dispatch(showGlobalError(error));
    }
  };
}

export function fetchOncologyTravelTypes() {
  return async (dispatch) => {
    try {
      dispatch(receiveOncologyTravelTypesLoading(true));
      const response = await dispatch(
        requestOncologyEpisodeDetails('oncology_travel_types')
      );
      dispatch(receiveOncologyTravelTypes(response.data.data));
      dispatch(receiveOncologyTravelTypesLoading(false));
    } catch (error) {
      dispatch(receiveOncologyTravelTypesLoading(false));
      dispatch(showGlobalError(error));
    }
  };
}

export function receiveEpisodeUser(user) {
  return {
    type: 'RECEIVE_EPISODE_USER',
    user,
  };
}

export function receiveEligiblePatient(eligiblePatient) {
  return {
    type: 'RECEIVE_ELIGIBLE_PATIENT',
    eligiblePatient,
  };
}

export function receiveEligiblePatientNotFound() {
  return {
    type: 'RECEIVE_ELIGIBLE_PATIENT_NOT_FOUND',
  };
}

export function resetVerifiedPatient() {
  return {
    type: 'RESET_VERIFIED_PATIENT',
  };
}

export function receiveEligiblePatientActive(active) {
  return {
    type: 'RECEIVE_ELIGIBLE_PATIENT_ACTIVE',
    active,
  };
}

export function receiveEpisodeInsurer(insurer) {
  return {
    type: 'RECEIVE_EPISODE_INSURER',
    insurer,
  };
}

export function receiveEpisode(episode, merge = false) {
  return {
    type: 'RECEIVE_EPISODE',
    episode,
    merge,
  };
}

export function receiveEpisodes(episodes) {
  return {
    type: 'RECEIVE_EPISODES',
    episodes,
  };
}

function receiveSubGroups(subGroups) {
  return {
    type: 'RECEIVE_SUBGROUPS',
    subGroups,
  };
}

function receiveCurrentStep(currentStep) {
  return {
    type: 'RECEIVE_CURRENT_STEP',
    currentStep,
  };
}

function receiveSubmissions(submissions) {
  return {
    type: 'RECEIVE_SUBMISSIONS',
    submissions,
  };
}

function receiveOncologyEpisodeTypes(episodeTypes) {
  return {
    type: 'RECEIVE_ONCOLOGY_EPISODE_TYPES',
    episodeTypes,
  };
}

function receiveOncologyGuidanceTypes(guidanceTypes) {
  return {
    type: 'RECEIVE_ONCOLOGY_GUIDANCE_TYPES',
    guidanceTypes,
  };
}

function receiveOncologyTreatmentTypes(treatmentTypes) {
  return {
    type: 'RECEIVE_ONCOLOGY_TREATMENT_TYPES',
    treatmentTypes,
  };
}

function receiveOncologyTravelTypes(travelTypes) {
  return {
    type: 'RECEIVE_ONCOLOGY_TRAVEL_TYPES',
    travelTypes,
  };
}

function receiveEpisodeLoading(loading) {
  return {
    type: 'RECEIVE_EPISODE_LOADING',
    loading,
  };
}

function receiveOncologyEpisodeTypesLoading(loading) {
  return {
    type: 'RECEIVE_ONCOLOGY_EPISODE_TYPES_LOADING',
    loading,
  };
}

function receiveOncologyGuidanceTypesLoading(loading) {
  return {
    type: 'RECEIVE_ONCOLOGY_GUIDANCE_TYPES_LOADING',
    loading,
  };
}

function receiveOncologyTreatmentTypesLoading(loading) {
  return {
    type: 'RECEIVE_ONCOLOGY_TREATMENT_TYPES_LOADING',
    loading,
  };
}

function receiveOncologyTravelTypesLoading(loading) {
  return {
    type: 'RECEIVE_ONCOLOGY_TRAVEL_TYPES_LOADING',
    loading,
  };
}
