import { Text } from 'grommet';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const propTypes = {
  targetDate: PropTypes.string,
};

const defaultProps = {
  targetDate: '12/15/2021',
};

const MfaMessage = ({ targetDate }) => {
  const daysRemaining = Math.floor(
    (new Date(targetDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24)
  );

  return (
    <>
      {daysRemaining > 0 && daysRemaining <= 30 && (
        <InfoText color="status-warning" size="small" margin={{ top: 'small' }}>
          Please note that starting in {daysRemaining} day
          {daysRemaining === 1 ? ', ' : 's, '}
          we will be requiring multi-factor authentication. Please edit your
          profile, enter your phone number, and check the box for opting into
          SMS Multi-factor Authentication.
        </InfoText>
      )}
    </>
  );
};

const InfoText = styled(Text)`
  background-color: lightyellow;
  padding: 8px;
  border-radius: 5px;
`;

MfaMessage.defaultProps = defaultProps;
MfaMessage.propTypes = propTypes;

export default MfaMessage;
