import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  roles: [],
  permissions: [],
  loading: false,
});

export default (state = nullState, action) => {
  switch (action.type) {
    case 'RECEIVE_ROLES':
      return { ...state, roles: action.roles.map(formatData) };
    case 'RECEIVE_ROLE': {
      const role = formatData(action.role);
      const roles = [...state.roles];
      const index = roles.map(({ id }) => id).indexOf(role.id);

      if (index === -1) {
        roles.push(role);
      } else {
        roles[index] = role;
      }

      return { ...state, roles };
    }
    case 'RECEIVE_PERMISSIONS': {
      const newPermissions = action.permissions.map(formatData);
      const permissions = action.append
        ? [...state.permissions, ...newPermissions]
        : newPermissions;
      return { ...state, permissions };
    }
    case 'RECEIVE_PERMISSION': {
      const permission = formatData(action.permission);
      const permissions = [...state.permissions];
      const index = permissions.indexOf(permission);

      if (index === -1) {
        permissions.push(permission);
      } else {
        permissions[index] = permission;
      }

      return { ...state, permissions };
    }
    case 'RECEIVE_ROLE_LOADING':
      return { ...state, loading: action.roleLoading };
    default:
      return state;
  }
};
