import { formatData } from 'app/utils/reducerUtils';

export const nullState = Object.freeze({
  loading: false,
  insurer: null,
  insurers: [],
  tradingPartners: [],
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_INSURERS':
      return { ...state, insurers: action.insurers.map(formatData) };
    case 'RECEIVE_INSURER':
      return { ...state, insurer: formatData(action.insurer) };
    case 'RECEIVE_TRADING_PARTNERS':
      return {
        ...state,
        tradingPartners: action.tradingPartners.map(formatData),
      };
    case 'RECEIVE_INSURER_LOADING':
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};
