"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.newColors = void 0;
var colors = {
    'grey-3-a': '#3b3b3b',
    'grey-4-a': '#686868',
    'light-purple': '#f7f2ff',
    'light2-a': '#f9f9f9',
    'neutral-1-a': '#3b83b3',
    'neutral-2-a': '#dc2878',
    'neutral-2-b': '#e35896',
    'neutral-2-c': '#f8e7ee',
    'neutral-2-t': '#de337f',
    'neutral-2': '#dc2878',
    'neutral-3-a': '#734bc3',
    'neutral-4-a': '#6d738b',
    'neutral-4-t': '#525a76',
    'neutral-4': '#49516f',
    'primary-blue': '#64b5f6',
    'purple-disabled': '#dbcff3',
    black: '#333',
    blue: '#0a64a0',
    brand: '#2991d6',
    cellGreen: '#ccffcc',
    cellGreenDark: '#b3ffb3',
    cellRed: 'rgba(255, 50, 77, 0.25)',
    cellRedDark: 'rgba(255, 25, 55, 0.25)',
    cellYellow: 'rgba(255, 214, 2, 0.25)',
    cellYellowDark: 'rgba(232, 194, 0, 0.25)',
    critical: '#ff324d',
    darkOffWhite: '#fcfcfc',
    grey: '#eaeaea',
    gold: '#B68949',
    goldLight: '#FCF7E7',
    goldLightTranslucent: 'rgba(252,247,231,.85)',
    light1: '#e8e8e8',
    light2: '#f5f5f5',
    light3: '#f8f8f8',
    lightBlue: '#00cceb',
    lightGreen: '#8cc800',
    lightGrey: '#d9d9d9',
    maroon: '#dc2878',
    medGrey: '#666666',
    offWhite: '#fefefe',
    ok: '#8cc800',
    printSectionHeader: '#DEE9F9',
    purple: '#865cd6',
    secondary: '#265d90',
    silver: '#909290',
    silverLight: '#F4F4F4',
    silverLightTranslucent: 'rgba(244,244,244,.85)',
    transparentGrey: '#rgba(0, 0, 0, 0.15)',
    unknown: '#a8a8a8',
    warning: '#ffd602',
    white: '#ffffff',
    yellow: '#fabb1e',
};
exports.newColors = {
    primaryRed: '#A53723',
};
exports.default = colors;
