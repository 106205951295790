import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  form: null,
  loading: false,
  formModelAttributeOptions: null,
  formModelAttributesLoading: false,
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_FORM':
      return { ...state, form: formatData(action.form.data), loading: false };
    case 'RECEIVE_FORM_LOADING':
      return { ...state, loading: action.formLoading };
    case 'RECEIVE_FORM_MODEL_ATTRIBUTES':
      return {
        ...state,
        formModelAttributeOptions:
          action.formModelAttributeOptions.data.map(formatData),
        formModelAttributesLoading: false,
      };
    case 'RECEIVE_FORM_MODEL_ATTRIBUTES_LOADING':
      return {
        ...state,
        formModelAttributesLoading: action.formModelAttributesLoading,
      };
    default:
      return state;
  }
};
