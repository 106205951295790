import React from 'react';

import { TestID } from 'app/test/test-id';

/**
 * A custom filter for React Data Grid that displays
 * an input with a guard that ensures only numbers
 * and dashes can be input.
 */
const NumericFilter = ({ onChange, column }) => (
  <div>
    <div className="form-group">
      <input
        data-testid={`${TestID.DataGrid.NumericFilter}--${column?.key}`}
        className="form-control input-sm"
        placeholder="123"
        onChange={(event) => {
          event.target.value = event.target.value.replace(/[^\d\\.-]+/, '');
          onChange({ filterTerm: event.target.value, column });
        }}
      />
    </div>
  </div>
);

export default NumericFilter;
