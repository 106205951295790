import { connect } from 'react-redux';
import Header from 'app/components/Header';

import { openConversation } from 'app/actions/messageActions';
import { logout } from 'app/actions/sessionActions';
import { toggleConversationList } from 'app/actions/uiActions';

import { capitalizeWords } from 'app/utils/methods';

const mapStateToProps = ({ session, message, ui }) => {
  const { currentUser } = session;

  const { userStatuses } = message;

  const { conversationListOpen } = ui;

  const conversations =
    message && message.conversations
      ? [...message.conversations].filter((isPresent) => isPresent)
      : [];

  const conversationsWithAuthors = conversations.map((convo) => ({
    ...convo,
    messages: convo.messages.filter(({ notification }) => !notification),
    participants: (convo.participants || []).map((participant) => ({
      ...participant,
      name: capitalizeWords(participant.name),
      status:
        (userStatuses[participant.id] && userStatuses[participant.id].status) ||
        'offline',
    })),
  }));

  return {
    user: currentUser,
    conversations: conversationsWithAuthors,
    conversationsLoaded: message.progress === 100,
    features: session.features,
    conversationListOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    openConversation: (patient) => dispatch(openConversation(patient)),
    toggleConversationList: (status) =>
      dispatch(toggleConversationList(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
