import axios from 'axios';

import { unformatObject } from 'app/utils/reducerUtils';
import { fetchCurrentStep } from 'app/actions/episodeActions';
import { showGlobalError } from 'app/actions/uiActions';

export function fetchSteps(episodeId) {
  return function (dispatch, getState) {
    return axios
      .get(`${CARE_API_URL}/episodes/${episodeId}/steps`, {
        headers: {
          Authorization: `Bearer ${getState().session.coreServiceToken}`,
        },
      })
      .then((steps) => dispatch(receiveSteps(steps.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function fetchStep(episodeId, stepId) {
  return function (dispatch, getState) {
    return axios
      .get(`${CARE_API_URL}/episodes/${episodeId}/steps/${stepId}`, {
        headers: {
          Authorization: `Bearer ${getState().session.coreServiceToken}`,
        },
      })
      .then((step) => dispatch(receiveStep(step.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Updates the currently selected step in the reducer.
 *
 * @param {object} step - the current step object
 * @return {promise} a promise that resolves after updating.
 */
export function setActiveStep(step) {
  return (dispatch) => Promise.resolve(dispatch(receiveActiveStep(step)));
}

/**
 * Calls the API to toggle a task complete or incomplete.
 * It then fetches the episode's current step, in case it
 * has changed, as well as the updated step data.
 */
export function toggleTaskComplete(episodeId, stepId, taskId, complete) {
  return (dispatch, getState) => {
    return axios
      .patch(
        `${CARE_API_URL}/episodes/${episodeId}/steps/${stepId}/tasks/${taskId}`,
        {
          task: { complete },
        },
        {
          headers: { Authorization: getState().session.coreServiceToken },
        }
      )
      .then(() => {
        dispatch(fetchCurrentStep(episodeId));
        return dispatch(fetchStep(episodeId, stepId));
      })
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Calls the API to add a date to a task and update complete status.
 * It then fetches the episode's current step, in case it
 * has changed, as well as the updated step data.
 */
export function updateTaskDate(episodeId, stepId, taskId, meta) {
  return (dispatch, getState) => {
    return axios
      .patch(
        `${CARE_API_URL}/episodes/${episodeId}/steps/${stepId}/tasks/${taskId}`,
        {
          task: { complete: Boolean(meta.date), meta },
        },
        {
          headers: { Authorization: getState().session.coreServiceToken },
        }
      )
      .then(() => {
        dispatch(fetchCurrentStep(episodeId));
        return dispatch(fetchStep(episodeId, stepId));
      })
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function addTask(episodeId, stepId, task) {
  return (dispatch, getState) => {
    return axios
      .post(
        `${CARE_API_URL}/episodes/${episodeId}/steps/${stepId}/tasks`,
        {
          task,
        },
        {
          headers: { Authorization: getState().session.coreServiceToken },
        }
      )
      .then(() => dispatch(fetchStep(episodeId, stepId)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function deleteTask(episodeId, stepId, taskId) {
  return (dispatch, getState) => {
    return axios
      .delete(
        `${CARE_API_URL}/episodes/${episodeId}/steps/${stepId}/tasks/${taskId}`,
        {
          headers: { Authorization: getState().session.coreServiceToken },
        }
      )
      .then(() => dispatch(fetchStep(episodeId, stepId)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Makes a GET request to the StepKey API.
 *
 * @return {promise} A promise that resolves after fetching StepKeys.
 */
export function fetchStepKeys() {
  return async (dispatch, getState) => {
    try {
      const { data } = await axios.get(`${CARE_API_URL}/step_keys`, {
        params: { include: 'forms,task_keys' },
        headers: {
          Authorization: `Bearer ${getState().session.coreServiceToken}`,
        },
      });

      dispatch(receiveStepKeys(data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

/**
 * Makes a PATCH request to the StepKey API.
 *
 * @param {integer} id The ID of the StepKey to update.
 * @param {object} stepKey An object with params for updating a StepKey.
 * @return {promise} A promise that resolves after updating a StepKey.
 */
export function updateStepKey(id, stepKey) {
  return async (dispatch) => {
    try {
      const { data } = await dispatch(patchStepKey(id, stepKey));
      dispatch(receiveStepKey(data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function patchStepKey(id, stepKey) {
  return (dispatch, getState) => {
    return axios.patch(
      `${CARE_API_URL}/step_keys/${id}`,
      unformatObject({ stepKey }),
      {
        headers: {
          Authorization: `Bearer ${getState().session.coreServiceToken}`,
        },
      }
    );
  };
}

export function receiveSteps(steps) {
  return {
    type: 'RECEIVE_STEPS',
    steps,
  };
}

export function receiveStep(step) {
  return {
    type: 'RECEIVE_STEP',
    step,
  };
}

function receiveActiveStep(step) {
  return {
    type: 'RECEIVE_ACTIVE_STEP',
    step,
  };
}

export function receiveStepKeys(stepKeys) {
  return {
    type: 'RECEIVE_STEP_KEYS',
    stepKeys,
  };
}

export function receiveStepKey(stepKey) {
  return {
    type: 'RECEIVE_STEP_KEY',
    stepKey,
  };
}
