import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  inquiry: {},
  loading: false,
});

export { nullState };

export default (state = nullState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case 'RECEIVE_INQUIRY':
      return { ...state, inquiry: formatData(action.inquiry) };
    case 'RECEIVE_INQUIRY_LOADING':
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};
