"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialRollupState = void 0;
var reducerUtils_1 = require("app/utils/reducerUtils");
var rollupActions_1 = require("app/actions/rollupActions");
exports.initialRollupState = {
    error: '',
    loading: false,
    rollups: [],
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialRollupState; }
    switch (action.type) {
        case rollupActions_1.RECEIVE_ROLLUPS: {
            var newRollups = action.rollups.map(reducerUtils_1.formatData);
            var rollups = action.append
                ? __spreadArray(__spreadArray([], state.rollups, true), newRollups, true) : newRollups;
            return __assign(__assign({}, state), { rollups: rollups });
        }
        case rollupActions_1.RECEIVE_ROLLUPS_LOADING:
            return __assign(__assign({}, state), { loading: !!(action === null || action === void 0 ? void 0 : action.loading) });
        case rollupActions_1.RECEIVE_ROLLUPS_ERROR:
            return __assign(__assign({}, state), { error: action === null || action === void 0 ? void 0 : action.error });
        default:
            return state;
    }
}
exports.default = reducer;
