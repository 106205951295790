import axios from 'axios';
import { chunk } from 'lodash';
import { subscribeToMessages } from 'app/actions/actioncableActions';
import { showGlobalError } from 'app/actions/uiActions';

export { openConversation } from './openConversation';

/**
 * Fetches and receives user statuses from the message service
 * for users in conversations with the current user
 *
 * @return {promise} - a promise that resolves after the statuses are retrieved
 */
export function requestUserStatuses() {
  return function (dispatch) {
    return dispatch(fetchUserStatuses())
      .then((resp) => dispatch(receiveUserStatuses(resp.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function fetchUserStatuses() {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios.get(`${MESSAGE_API_URL}/user_statuses`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

/**
 * Fetches and receives the current user's conversations from the message service.
 *
 * @param {object} params An optional object of params to include in the request.
 * @return {promise} - a promise that resolves after the conversations are retrieved
 */
export function requestConversations(params = {}, progress = null) {
  return function (dispatch) {
    return dispatch(fetchConversations({ status: 'active', ...params }))
      .then((resp) => {
        const convos = resp.data.data;

        convos.forEach((convo) => dispatch(subscribeToMessages(convo.id)));

        return dispatch(receiveConversations(convos, progress));
      })
      .catch((error) => {
        dispatch(showGlobalError(error));
      });
  };
}

/**
 * Fetches any conversation with a given patient as a participant.
 *
 * @param {integer} patientId - the ID of the patient to filter conversations by.
 * @return {promise} a promise that resolves with the API result.
 */
export const requestConversationsByPatient = (patientId) => {
  return (dispatch) => {
    if (!patientId) {
      return console.warn('Request for conversations is missing a `patientId`');
    }

    return dispatch(
      fetchConversations({ any: true, 'filter[patient]': patientId })
    );
  };
};

/**
 * Fetch conversations in batches of 20 until all
 * have been loaded.
 *
 * @param {array} ids An array of conversation IDs to load.
 * @return {promise} A promise that resolves when all data is loaded.
 */
export const requestConversationsInBatches = (ids, index = 0) => {
  return async (dispatch) => {
    const batches = chunk(ids, 20);
    const batch = batches[index];

    if (batches.length === 0) {
      return dispatch(receiveConversations([], 100));
    }

    if (index > batches.length - 1) {
      return dispatch(setConnectionStatus({ conversations: true }));
    }

    const progress = ((index + 1) / batches.length) * 100;
    await dispatch(requestConversations({ 'filter[id]': batch }, progress));

    return dispatch(requestConversationsInBatches(ids, index + 1));
  };
};

/**
 * Fetches any conversation with a given patient as a participant
 * and stores the results in the redux store.
 *
 * @param {integer} patientId - the ID of the patient to filter conversations by.
 * @return {promise} a promise that resolves with the API result.
 */
export function fetchPatientConversations(patientId) {
  return async (dispatch) => {
    try {
      const response = await dispatch(requestConversationsByPatient(patientId));
      return dispatch(receiveConversations(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function fetchConversations(params = {}) {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios.get(`${MESSAGE_API_URL}/conversations`, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

/**
 * Fetches and receives user statuses from the message service
 * for users in conversations with the current user
 *
 * @return {promise} - a promise that resolves after the statuses are retrieved
 */
export function requestMessages(conversationId, maxMessageId, params) {
  return function (dispatch) {
    return dispatch(fetchMessages(conversationId, maxMessageId, params))
      .then((resp) => dispatch(receiveMessages(conversationId, resp.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function fetchMessages(conversationId, maxMessageId, params) {
  return function (dispatch, getState) {
    const state = getState();

    const token = state.session.coreServiceToken;

    return axios.get(
      `${MESSAGE_API_URL}/conversations/${conversationId}/messages`,
      {
        params: { max_id: maxMessageId, ...params },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };
}

/**
 * Deletes a message from the Redux store.
 * @param {number} id             identifier for the message
 * @param {number} conversationId identifier for the message conversation
 */
export function deleteMessage(id, conversationId) {
  return function (dispatch, getState) {
    return dispatch(
      receiveMessage({
        id,
        conversationId,
        readBy: [],
        attachments: [],
        author: { id: getState().session.currentUser.id },
        createdAt: new Date(),
        updatedAt: new Date(),
        _delete: true,
      })
    );
  };
}

export function receiveUserStatuses(userStatuses) {
  return {
    type: 'RECEIVE_USER_STATUSES',
    userStatuses,
  };
}

export function receiveUserStatus(userStatus) {
  return {
    type: 'RECEIVE_USER_STATUS',
    userStatus,
  };
}

export function receiveConversations(conversations, progress) {
  return {
    type: 'RECEIVE_CONVERSATIONS',
    conversations,
    progress,
  };
}

export function receiveConversation(conversation) {
  return {
    type: 'RECEIVE_CONVERSATION',
    conversation,
  };
}

export function receiveMessages(conversationId, messages) {
  return {
    type: 'RECEIVE_MESSAGES',
    conversationId,
    messages,
  };
}

export function receiveMessage(message) {
  return {
    type: 'RECEIVE_MESSAGE',
    message,
  };
}

export function deleteConversation(id) {
  return {
    type: 'DELETE_CONVERSATION',
    id,
  };
}

export const setConnectionStatus = (connected) => {
  return {
    type: 'RECEIVE_CONNECTION_STATUS',
    connected,
  };
};
