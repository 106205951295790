import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  closureReasons: [],
  inactiveReasons: [],
  referralSources: [],
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_CLOSURE_REASONS':
      return {
        ...state,
        closureReasons: action.closureReasons.map(formatData),
      };
    case 'RECEIVE_INACTIVE_REASONS':
      return {
        ...state,
        inactiveReasons: action.inactiveReasons.map(formatData),
      };
    case 'RECEIVE_REFERRAL_SOURCES':
      return {
        ...state,
        referralSources: action.referralSources.map(formatData),
      };
    case 'RECEIVE_CLOSURE_REASON': {
      const closureReasons = [...state.closureReasons];
      const closureReason = formatData(action.closureReason);

      if (action.method === 'post') {
        closureReasons.push(closureReason);
      } else if (action.method === 'patch') {
        const index = closureReasons.findIndex(
          ({ id }) => id === closureReason.id
        );
        if (index !== -1) closureReasons[index] = closureReason;
      } else if (action.method === 'delete') {
        const index = closureReasons.findIndex(
          ({ id }) => id === closureReason.id
        );
        if (index !== -1) closureReasons.splice(index, 1);
      }

      return { ...state, closureReasons };
    }
    case 'RECEIVE_INACTIVE_REASON': {
      const inactiveReasons = [...state.inactiveReasons];
      const inactiveReason = formatData(action.inactiveReason);

      if (action.method === 'post') {
        inactiveReasons.push(inactiveReason);
      } else if (action.method === 'patch') {
        const index = inactiveReasons.findIndex(
          ({ id }) => id === inactiveReason.id
        );
        if (index !== -1) inactiveReasons[index] = inactiveReason;
      } else if (action.method === 'delete') {
        const index = inactiveReasons.findIndex(
          ({ id }) => id === inactiveReason.id
        );
        if (index !== -1) inactiveReasons.splice(index, 1);
      }

      return { ...state, inactiveReasons };
    }
    case 'RECEIVE_REFERRAL_SOURCE': {
      const referralSources = [...state.referralSources];
      const referralSource = formatData(action.referralSource);

      if (action.method === 'post') {
        referralSources.push(referralSource);
      } else if (action.method === 'patch') {
        const index = referralSources.findIndex(
          ({ id }) => id === referralSource.id
        );
        if (index !== -1) referralSources[index] = referralSource;
      } else if (action.method === 'delete') {
        const index = referralSources.findIndex(
          ({ id }) => id === referralSource.id
        );
        if (index !== -1) referralSources.splice(index, 1);
      }

      return { ...state, referralSources };
    }
    default:
      return state;
  }
};
