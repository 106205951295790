import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  episode: {},
  episodes: [],
  user: {},
  verifiedPatient: {},
  subGroups: [],
  submissions: [],
  loading: false,
  active: false,
  dependents: [],
  subscriber: null,
  insurer: null,
  episodeTypes: [],
  episodeTypesLoading: false,
  guidanceTypes: [],
  guidanceTypesLoading: false,
  treatmentTypes: [],
  treatmentTypesLoading: false,
  travelTypes: [],
  travelTypesLoading: false,
});

export { nullState };

export default (state = nullState, action) => {
  switch (action.type) {
    case 'RECEIVE_EPISODES': {
      const episodes = action.episodes.map(formatData);
      return { ...state, episodes };
    }
    case 'RECEIVE_EPISODE': {
      const episode = action.merge
        ? { ...state.episode, ...formatData(action.episode) }
        : formatData(action.episode);
      return { ...state, episode };
    }
    case 'RECEIVE_CURRENT_STEP':
      return {
        ...state,
        episode: {
          ...state.episode,
          currentStep: formatData(action.currentStep),
        },
      };
    case 'RECEIVE_ELIGIBLE_PATIENT':
      return {
        ...state,
        verifiedPatient: {
          ...formatData(action.eligiblePatient),
          verified: true,
        },
      };
    case 'RECEIVE_ELIGIBLE_PATIENT_ACTIVE':
      return { ...state, active: action.active };
    case 'RECEIVE_ELIGIBLE_PATIENT_NOT_FOUND':
      return { ...state, user: {}, verifiedPatient: { verified: false } };
    case 'RESET_VERIFIED_PATIENT':
      return { ...state, user: {}, verifiedPatient: {} };
    case 'RECEIVE_EPISODE_INSURER':
      return { ...state, insurer: formatData(action.insurer) };
    case 'RECEIVE_EPISODE_USER':
      return { ...state, user: formatData(action.user) };
    case 'RECEIVE_SUBGROUPS':
      return { ...state, subGroups: action.subGroups };
    case 'RECEIVE_SUBMISSIONS':
      return { ...state, submissions: action.submissions.map(formatData) };
    case 'RECEIVE_EPISODE_LOADING':
      return { ...state, loading: action.loading };
    case 'RECEIVE_ONCOLOGY_EPISODE_TYPES_LOADING':
      return { ...state, episodeTypesLoading: action.loading };
    case 'RECEIVE_ONCOLOGY_GUIDANCE_TYPES_LOADING':
      return { ...state, guidanceTypesLoading: action.loading };
    case 'RECEIVE_ONCOLOGY_TREATMENT_TYPES_LOADING':
      return { ...state, assessmentTypesLoading: action.loading };
    case 'RECEIVE_ONCOLOGY_TRAVEL_TYPES_LOADING':
      return { ...state, travelTypesLoading: action.loading };
    case 'RECEIVE_ONCOLOGY_EPISODE_TYPES':
      return { ...state, episodeTypes: action.episodeTypes.map(formatData) };
    case 'RECEIVE_ONCOLOGY_GUIDANCE_TYPES':
      return { ...state, guidanceTypes: action.guidanceTypes.map(formatData) };
    case 'RECEIVE_ONCOLOGY_TRAVEL_TYPES':
      return { ...state, travelTypes: action.travelTypes.map(formatData) };
    case 'RECEIVE_ONCOLOGY_TREATMENT_TYPES':
      return {
        ...state,
        treatmentTypes: action.treatmentTypes.map(formatData),
      };
    default:
      return state;
  }
};
