"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchClientSystemJobsParams = exports.fetchClientProceduresParams = exports.fetchClientsParams = exports.fetchClientParams = void 0;
exports.fetchClientParams = {
    include: 'last_eligibility_load_job',
};
exports.fetchClientsParams = {
    sort: 'clients.id',
    'page[size]': 1000,
};
exports.fetchClientProceduresParams = {
    sort: 'offered_procedures.name',
    'page[size]': 1000,
};
exports.fetchClientSystemJobsParams = {
    sort: '-id',
    'page[size]': 1000,
};
