import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Login from 'app/components/Login';
import { loginUser } from 'app/actions/sessionActions';

const mapStateToProps = ({ session }, { heading, modal }) => {
  const error = session.error.status
    ? session.error.status === 404
      ? 'Incorrect username or password'
      : 'There was an error logging in, please try again'
    : session.error.message;

  return {
    heading,
    modal,
    error,
    isLoading: session.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: async (user, history = {}) => {
      await dispatch(loginUser(user, history));
    },
  };
};

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withRouter(LoginContainer);
