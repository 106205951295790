import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import FeatureSwitch from 'app/components/Common/FeatureSwitch';
import NoMatch from 'app/components/Common/NoMatch';

/**
 * Adapts the functionality of a FeatureSwitch
 * to a Route, so that it can be rendered as
 * the child of a Switch component.
 * @param {string}  permission the permission required to access the route
 * @param {boolean} exact      true if the path needs to match exactly
 * @param {string}  path       path to match
 * @param {node}    children   renders if the path matches and the user has permission
 */
export function AuthorizedRoute({ permission, exact, path, children }) {
  return (
    <Route exact={exact} path={path}>
      <Fragment>
        <FeatureSwitch name={permission} state="on">
          {children}
        </FeatureSwitch>

        <FeatureSwitch name={permission} state="off">
          <NoMatch />
        </FeatureSwitch>
      </Fragment>
    </Route>
  );
}

AuthorizedRoute.propTypes = {
  permission: PropTypes.string,
  exact: PropTypes.bool,
  path: PropTypes.string,
  children: PropTypes.node,
};

AuthorizedRoute.defaultProps = {
  exact: false,
};

export default AuthorizedRoute;
