export const cdnUrl = 'https://carrum-cdn.s3.amazonaws.com';

export const digestKey = 'care_service_app';

export const digest =
  'U2FsdGVkX1/L6cr8alC1SOFRlIoUBIbOmN902FVQGpFse0LyJLqRPjA02AXbsVJO';

export const employmentDetailsOptions = [
  { value: 'employee', label: 'Employee', active: true },
  { value: 'retiree', label: 'Retiree', active: true },
  { value: 'cobra', label: 'COBRA', active: true },
  { value: 'dependent', label: 'Dependent', active: true },
  { value: 'spouse', label: 'Spouse', active: true },
  { value: 'hr_rep', label: 'HR Representative', active: true },
  { value: 'injured_worker', label: 'Injured Worker', active: true },
  { value: 'unknown', label: 'Unknown', active: true },
];

export const inquiryMethodOptions = [
  { value: 'online', label: 'Online', active: true },
  { value: 'phone', label: 'Phone', active: true },
  { value: 'email', label: 'Email', active: true },
];

export const statusOptions = [
  { value: 'active', label: 'Active', active: true },
  { value: 'inactive', label: 'Needs Followup', active: true },
  { value: 'closed', label: 'Closed', active: true },
];

export const deductibleTypeOptions = [
  {
    label: 'Covered / Federal Minimum',
    value: 'covered_federal_minimum',
    active: true,
  },
  {
    label: 'Covered / Full Deductible',
    value: 'covered_full_deductible',
    active: true,
  },
  {
    label: 'Not Covered / Federal Minimum',
    value: 'not_covered_federal_minimum',
    active: true,
  },
  {
    label: 'Not Covered / Full Deductible',
    value: 'not_covered_full_deductible',
    active: true,
  },
];

export const genderOptions = [
  { label: 'Male', value: 'Male', active: true },
  { label: 'Female', value: 'Female', active: true },
  { label: 'Other', value: 'Other', active: true },
  { label: 'Unknown', value: 'Unknown', active: true },
  { label: 'Non-binary', value: 'Non-binary', active: true },
];

export const maritalStatusOptions = [
  { label: 'Single', value: 'single', active: true },
  { label: 'Married', value: 'married', active: true },
  {
    label: 'Domestic Partnership',
    value: 'domestic_partnership',
    active: true,
  },
  { label: 'Widowed', value: 'widowed', active: true },
  { label: 'Divorced', value: 'divorced', active: true },
  { label: 'Separated', value: 'separated', active: true },
];

export const ethnicityOptions = [
  { label: 'White/Non-Hispanic', value: 'white_non_hispanic', active: true },
  { label: 'Hispanic', value: 'hispanic', active: true },
  { label: 'African American', value: 'african_american', active: true },
  { label: 'Asian', value: 'Asian', active: true },
  {
    label: 'Native Hawaiian/Pacific Islander',
    value: 'native_hawaiian_pacific_islander',
    active: true,
  },
  {
    label: 'Native American/Eskimo/Aleut',
    value: 'native_american_eskimo_aleut',
    active: true,
  },
  { label: 'Not Listed', value: 'not_listed', active: true },
];

export const platformFeeTypeOptions = [
  { label: 'Flat Rate', value: 'flat_rate', active: true },
  { label: 'PEPM', value: 'pepm', active: true },
];

// Search tags: serviceTypes service types
export const serviceTypeMap = {
  cardiac: 'Cardiac',
  gastrointestinal: 'Gastrointestinal',
  generalSurgery: 'General Surgery',
  musculoskeletal: 'Musculoskeletal',
  oncology: 'Oncology',
  womensHealth: "Women's Health",
};

/**
 * @example
 * [
 *   { active: true, label: 'Cardiac', value: 'cardiac' },
 *   ...
 * ]
 */
export const serviceTypeOptions = Object.entries(serviceTypeMap).map(
  ([value, label]) => ({
    active: true,
    label,
    value,
  })
);

export const smokingStatusOptions = [
  { label: 'Current Smoker', value: 'current_smoker', active: true },
  {
    label: 'Former Smoker - quit in the last 3 months',
    value: 'former_smoker_quit_in_the_last_3_months',
    active: true,
  },
  {
    label: 'Former Smoker - quit between 3 and 6 months ago',
    value: 'former_smoker_quit_between_3_and_6_months_ago',
    active: true,
  },
  {
    label: 'Former Smoker - quit more than 6 months ago',
    value: 'former_smoker_quit_more_than_6_months_ago',
    active: true,
  },
  { label: 'Never Smoked', value: 'never_smoked', active: true },
];

export const tierOptions = [
  { label: 'Bronze', value: 'bronze', active: true },
  { label: 'Silver', value: 'silver', active: true },
  { label: 'Gold', value: 'gold', active: true },
];

export const escalationStatusOptions = [
  {
    label: 'Active',
    value: 'active',
    active: true,
  },
  {
    label: 'Closed',
    value: 'closed',
    active: true,
  },
];

export const escalationTypeOptions = [
  {
    label: 'Activation Issue',
    value: 'activation_issue',
    active: true,
  },
  {
    label: 'Call Me (FAQ)',
    value: 'call_me_faq',
    active: true,
  },
  {
    label: 'Call Me (Provider Search)',
    value: 'call_me_provider_search',
    active: true,
  },
  {
    label: 'Call Me (Carrum Local)',
    value: 'call_me_carrum_local',
    active: true,
  },
  {
    label: 'COE/Provider Request',
    value: 'coe_provider_request',
    active: true,
  },
  {
    label: 'General Complaint',
    value: 'general_complaint',
    active: true,
  },
  {
    label: 'Misdirected Bill',
    value: 'misdirected_bill',
    active: true,
  },
  {
    label: 'Coverage is Medicare/Medicaid',
    value: 'coverage_is_medicare_medicaid',
    active: true,
  },
  {
    label: 'No COEs found',
    value: 'no_coes_found',
    active: true,
  },
  {
    label: 'No Surgery Recommendation',
    value: 'no_surgery_recommendation',
    active: true,
  },
  {
    label: 'Phone Outreach',
    value: 'phone_outreach',
    active: true,
  },
  {
    label: 'Schedule consultation request',
    value: 'schedule_consultation_request',
    active: true,
  },
  {
    label: 'Surgery Blocked by Mandate',
    value: 'surgery_blocked_by_mandate',
    active: true,
  },
  {
    label: 'Surgery Scheduled',
    value: 'surgery_scheduled',
    active: true,
  },
  {
    label: 'United Health Group Eligibility Check Failed',
    value: 'uhg_eligibility_check_failed',
    active: true,
  },
  {
    label: 'User is not patient',
    value: 'user_is_not_patient',
    active: true,
  },
  {
    label: 'VoluntaryFlex Education',
    value: 'voluntaryflex_education',
    active: true,
  },
  {
    label: 'Oncology Patient',
    value: 'oncology_episode',
    active: true,
  },
  {
    label: 'Other',
    value: 'other',
    active: true,
  },
];

export const exceptionTypeOptions = [
  {
    label: 'COE Clinical Exclusion Criteria',
    value: 'coe_clinical_exclusion_criteria',
    active: true,
  },
  {
    label: 'Emergency Surgery',
    value: 'emergency_surgery',
    active: true,
  },
  {
    label: 'Grace Period',
    value: 'grace-period',
    active: true,
  },
  {
    label: 'Inability to Secure a Travel Companion',
    value: 'inability_to_secure_a_travel_companion',
    active: true,
  },
  {
    label: 'Medical Necessity (after Consult only)',
    value: 'medical_necessity',
    active: true,
  },
  {
    label: 'Medically Unsafe to Travel (Per Clinical Documentation)',
    value: 'medically_unsafe_to_travel_per_clinical_documentation',
    active: true,
  },
  {
    label: 'Severe Financial Hardship',
    value: 'severe_financial_hardship',
    active: true,
  },
  {
    label: 'Significant Hardship: Caregiver Obligations',
    value: 'significant_hardship_caregiver_obligations',
    active: true,
  },
  {
    label: 'Surgery Not Currently Contract',
    value: 'surgery_not_currently_contract',
    active: true,
  },
  {
    label: 'Outside of 90 mile radius',
    value: 'outside_of_90_mile_radius',
    active: true,
  },
  {
    label: 'Outside of 150 miles radius',
    value: 'outside_of_150_miles_radius',
    active: true,
  },
  {
    label: 'Revision or replacement of alternate side',
    value: 'revision_or_replacement_of_alternate_side',
    active: true,
  },
  {
    label: 'Incorrectly routed to Carrum',
    value: 'incorrectly_routed_to_carrum',
    active: true,
  },
  {
    label: 'Surgery too complex',
    value: 'surgery_too_complex',
    active: true,
  },
  {
    label: 'Unwilling to modify lifestyle requirements',
    value: 'unwilling_to_modify_lifestyle_requirements',
    active: true,
  },
  {
    label: 'Other',
    value: 'other',
    active: true,
  },
];

export const exceptionStatusOptions = [
  {
    label: 'Not Applicable',
    value: 'not_applicable',
    active: true,
  },
  {
    label: 'Waiting for Documentation',
    value: 'waiting_for_documentation',
    active: true,
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    active: true,
  },
  {
    label: 'Approved',
    value: 'approved',
    active: true,
  },
  {
    label: 'Denied',
    value: 'denied',
    active: true,
  },
  {
    label: 'Under Appeal',
    value: 'under_appeal',
    active: true,
  },
];

export const mandatedProcedureOptions = [
  {
    label: 'Mandated',
    value: 'true',
    active: true,
  },
  {
    label: 'Voluntary',
    value: 'false',
    active: true,
  },
];

export const models = [
  'Client',
  'Contact',
  'EligiblePatient',
  'Episode',
  'Form',
  'Hospital',
  'Insurer',
  'OfferedProcedure',
  'Step',
  'StepKey',
  'Submission',
  'Task',
  'TaskKey',
  'User',
];

export const complianceEvents = [
  'create',
  'update',
  'view',
  'touch',
  'destroy',
];

export const minPasswordLength = 8;

export const passwordErrorMessage = {
  minLength: `Your password must be at least ${minPasswordLength} characters long`,
  number: 'Your password must contain at least one number',
  uppercase: 'Your password must contain at least one uppercase letter',
  match: 'Your passwords do not match',
};

export const providerStatusOptions = [
  { label: 'Retired', value: 'retired', active: true },
  {
    label: 'No longer affiliated with COE',
    value: 'no_longer_affiliated_with_coe',
    active: true,
  },
  { label: 'Deceased', value: 'deceased', active: true },
  { label: 'Quality of Care', value: 'quality_of_care', active: true },
  { label: 'Not Recertified', value: 'not_recertified', active: true },
  { label: 'Contractual', value: 'contractual', active: true },
  {
    label: 'Implementation/Quality Review Incomplete',
    value: 'implementation_quality_review_incomplete',
    active: true,
  },
  {
    label: 'Capacity - Not Accepting New Patients',
    value: 'not_accepting_new_patients',
    active: true,
  },
  {
    label: 'Temporarily Inactive',
    value: 'temporarily_inactive',
    active: true,
  },
  { label: 'Other', value: 'other', active: true },
];

export const clientTypeOptions = [
  { value: 'employer', label: 'Employer' },
  { value: 'partner', label: 'Partner' },
];

export const phoneCallStatusOptions = [
  {
    label: 'Not Applicable',
    value: 'not_applicable',
    active: true,
  },
  {
    label: 'Complete',
    value: 'complete',
    active: true,
  },
  {
    label: 'Incomplete',
    value: 'incomplete',
    active: true,
  },
];
