import styled from 'styled-components';

import { Box, Text } from 'grommet';
import CareAppLogo from 'app/assets/img/logos/care_app_logo.png';
import { Image as ImageRaw } from 'grommet';
import { Link as RouterLink } from 'react-router-dom';
import { Menu as MenuRaw } from 'grommet';
import { Spinner as SpinnerRaw } from 'app/components/Common/Core/Spinner';

export { Box };

export { CareAppLogo };

export const DropdownBox = styled(Box)`
  max-height: 37rem;
  overflow-y: auto;
`;

export const EnvironmentText = styled(Text)`
  background-color: #78b4f0;
  border-radius: 6px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin: 0.5rem;
  margin-right: auto;
  padding: 0.5rem;
`;

export { FormSearch, MailOption } from 'grommet-icons';

export const HeaderBox = styled(Box).attrs({
  align: 'center',
  background: 'light-1',
  border: { side: 'bottom', width: 'small', color: 'light-4' },
  direction: 'row',
  justify: 'start',
  pad: { vertical: 'xsmall', horizontal: 'small' },
  size: { width: 'full' },
})`
  left: 0;
  margin-bottom: 1rem;
  padding-left: 5rem !important;
  padding-right: ${({ padRight }) => (padRight ? '18.85rem' : 'initial')};
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 11;

  @media print {
    display: none;
  }
`;

export const Image = styled(ImageRaw).attrs({
  height: '100%',
  fit: 'contain',
})``;

export const Link = styled(RouterLink)`
  height: 38px;
`;

export const Menu = styled(MenuRaw).attrs({
  size: 'small',
})``;

export const MenuBox = styled(Box).attrs({
  align: 'center',
  direction: 'row',
  gap: 'small',
  justify: 'end',
})``;

export { default as MenuDropdown } from 'app/components/Header/MenuDropdown';

export { default as MenuItem } from 'app/components/Header/MenuItem';

export const MessageBox = styled(Box).attrs({
  pad: 'medium',
})``;

export { default as MessageListItem } from 'app/components/Common/ListItem/MessageListItem';

export const RelativeBox = styled(Box)`
  position: relative;
`;

export const Spinner = styled(SpinnerRaw).attrs({
  size: 20,
})``;

export { TextInput } from 'grommet';

export const TextInputBox = styled(Box).attrs({
  flex: false,
  pad: 'small',
})``;
