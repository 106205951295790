"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialRoundRobinTypesState = void 0;
var reducerUtils_1 = require("app/utils/reducerUtils");
var roundRobinActions_1 = require("app/actions/roundRobinActions");
exports.initialRoundRobinTypesState = {
    error: '',
    loading: false,
    roundRobinTypes: [],
};
function reducer(state, action) {
    if (state === void 0) { state = exports.initialRoundRobinTypesState; }
    switch (action.type) {
        case roundRobinActions_1.RECEIVE_ROUND_ROBIN_TYPES: {
            var newRoundRobinTypes = action.roundRobinTypes.map(reducerUtils_1.formatData);
            var roundRobinTypes = action.append
                ? __spreadArray(__spreadArray([], state.roundRobinTypes, true), newRoundRobinTypes, true) : newRoundRobinTypes;
            return __assign(__assign({}, state), { roundRobinTypes: roundRobinTypes });
        }
        case roundRobinActions_1.RECEIVE_ROUND_ROBIN_TYPES_LOADING:
            return __assign(__assign({}, state), { loading: !!(action === null || action === void 0 ? void 0 : action.loading) });
        case roundRobinActions_1.RECEIVE_ROUND_ROBIN_TYPES_ERROR:
            return __assign(__assign({}, state), { error: action === null || action === void 0 ? void 0 : action.error });
        default:
            return state;
    }
}
exports.default = reducer;
