import React from 'react';
import { Box, Image, Text } from 'grommet';

import illustration from 'app/assets/img/space-lost.png';

export function NoMatch() {
  return (
    <Box align="center" gap="medium">
      <Box margin={{ top: 'large' }}>
        <Image src={illustration} alignSelf="stretch" />
      </Box>

      <Box gap="small" align="center" width="medium">
        <Text size="xxlarge" weight="bold" textAlign="center">
          Woops...
        </Text>

        <Text textAlign="center">
          Either this page does not exist, or you are not authorized to view it.
        </Text>
      </Box>
    </Box>
  );
}

export default NoMatch;
