import { Filters, Formatters } from 'react-data-grid-addons';
const { SingleSelectFilter, MultiSelectFilter } = Filters;
const { ImageFormatter } = Formatters;

import DateRangeFilter from 'app/components/Common/DataGrid/DateRangeFilter';
import NumericFilter from 'app/components/Common/DataGrid/NumericFilter';

/**
 * Get the initial hidden values from local storage when the app first loads.
 */
const getStoredIsHidden = (type, column) => {
  const hiddenColumnValues =
    JSON.parse(localStorage.getItem(`${type}.hiddenColumnValues`)) || [];

  return hiddenColumnValues.includes(column);
};

/**
 * Get the initial width values from local storage when the app first loads.
 */
const getStoredWidth = (type, column) => {
  const storedWidths = JSON.parse(
    localStorage.getItem(`${type}.columnWidths`) || '{}'
  );
  if (storedWidths[column]) return parseInt(storedWidths[column], 10);
};

export const defaultTableColumns = {
  episodes: [
    {
      key: 'id',
      name: 'ID',
      width: 60,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'product_offering',
      name: 'Type',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
      width: 140,
    },
    {
      key: 'eligible_patient',
      name: 'Patient',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'carrum_id',
      name: 'Carrum ID',
      width: 135,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'client',
      name: 'Employer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'care_concierge',
      name: 'Patient Care Specialist',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
      width: 140,
    },
    {
      key: 'procedure',
      name: 'Procedure',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'provider',
      name: 'Hospital',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'physician',
      name: 'Physician',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
      filterValues: [],
    },
    {
      key: 'current_step',
      name: 'Current Step',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'relative_age',
      name: 'Days in Step',
      sortable: true,
      resizable: true,
      width: 100,
    },
    {
      key: 'created_at',
      name: 'Created At',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'scheduled_appointment',
      name: 'Scheduled',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'last_worked_on',
      name: 'Last Updated',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'remind_at',
      name: 'Reminder',
      sortable: true,
      resizable: true,
      filterable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'priority',
      name: 'Priority',
      sortable: true,
      resizable: true,
      width: 100,
    },
    {
      key: 'action_buttons',
      width: 80,
      name: '',
    },
  ],

  inquiries: [
    {
      key: 'id',
      name: 'ID',
      width: 60,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'eligible_patient',
      name: 'Patient',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'carrum_id',
      name: 'Carrum ID',
      width: 130,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'client',
      name: 'Employer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'description',
      name: 'Description',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'last_worked_on',
      name: 'Last Updated',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
  ],

  eligible_patients: [
    {
      key: 'carrum_id',
      name: 'Carrum ID',
      width: 120,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'client',
      name: 'Employer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'first_name',
      name: 'First Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'last_name',
      name: 'Last Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'email',
      name: 'Email',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'phone_number',
      name: 'Phone',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'date_of_birth',
      name: 'Date of Birth',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'employer_registration_id',
      name: 'Last 4 of SSN',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'insurer',
      name: 'Insurer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'plan_name',
      name: 'Plan Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  // Columns for nested Compliance grids. Ex. `/episodes/:id/compliance`
  paper_trails: [
    {
      filterable: true,
      filterRenderer: SingleSelectFilter,
      key: 'accessed_by',
      name: 'Accessed By',
      resizable: true,
      sortable: true,
      width: 150,
    },
    {
      filterable: true,
      filterRenderer: DateRangeFilter,
      key: 'created_at',
      name: 'Event Time',
      resizable: true,
      sortable: true,
    },
    {
      filterable: true,
      filterRenderer: SingleSelectFilter,
      key: 'event',
      name: 'Event Type',
      resizable: true,
      sortable: true,
    },
    {
      filterable: true,
      key: 'change_set',
      name: 'What Changed',
      resizable: true,
      sortable: false,
    },
  ],

  // Columns for top-level Compliance grids. Ex. `/compliance`
  paper_trails_top_level: [
    {
      filterable: true,
      filterRenderer: SingleSelectFilter,
      key: 'accessed_by',
      name: 'Accessed By',
      resizable: true,
      sortable: true,
      width: 150,
    },
    {
      filterable: true,
      filterRenderer: DateRangeFilter,
      key: 'created_at',
      name: 'Event Time',
      resizable: true,
      sortable: true,
    },
    {
      filterable: true,
      filterRenderer: SingleSelectFilter,
      key: 'event',
      name: 'Event Type',
      resizable: true,
      sortable: true,
    },
    {
      filterable: true,
      filterRenderer: SingleSelectFilter,
      key: 'item_type',
      name: 'Item Type',
      resizable: true,
      sortable: true,
    },
    {
      filterable: true,
      key: 'item_id',
      name: 'Item ID',
      resizable: true,
      sortable: true,
      width: 75,
    },
    {
      filterable: true,
      key: 'change_set',
      name: 'What Changed',
      resizable: true,
      sortable: false,
    },
  ],

  eligible_patient_load_errors: [
    {
      key: 'carrum_id',
      name: 'Carrum ID',
      width: 120,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'client',
      name: 'Employer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'created_at',
      name: 'Timestamp',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'job_hash',
      name: 'Job Hash',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'first_name',
      name: 'First Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'last_name',
      name: 'Last Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'date_of_birth',
      name: 'Date of Birth',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'employer_registration_id',
      name: 'Last 4 of SSN',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'insurer',
      name: 'Insurer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'error_class',
      name: 'Error Class',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'error_message',
      name: 'Error message',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  eligible_patient_not_confirmed_errors: [
    {
      key: 'carrum_id',
      name: 'Carrum ID',
      width: 120,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'client',
      name: 'Employer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'created_at',
      name: 'Timestamp',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'first_name',
      name: 'First Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'last_name',
      name: 'Last Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'date_of_birth',
      name: 'Date of Birth',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'employer_registration_id',
      name: 'Last 4 of SSN',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'insurer',
      name: 'Insurer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'error_class',
      name: 'Error Class',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'error_message',
      name: 'Error message',
      sortable: true,
      resizable: true,
    },
  ],

  users: [
    {
      key: 'carrum_id',
      name: 'Carrum ID',
      width: 130,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'name',
      name: 'Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'email',
      name: 'Email',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'phone_number',
      name: 'Phone Number',
      filterable: true,
      sortable: true,
      resizable: true,
      width: 125,
    },
    {
      key: 'client',
      name: 'Employer',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'date_of_birth',
      name: 'Date of Birth',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'roles',
      name: 'Roles',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'created_at',
      name: 'Created At',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'updated_at',
      name: 'Updated At',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'action_buttons',
      width: 40,
      name: '',
    },
  ],

  clients: [
    {
      key: 'logo',
      name: '',
      width: 60,
      formatter: ImageFormatter,
    },
    {
      key: 'name',
      name: 'Name',
      width: 540,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'code',
      name: 'Code',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'tier',
      name: 'Tier',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'type',
      name: 'Type',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'deductible_type',
      name: 'Deductible Type',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'employees_insured',
      name: 'Employees Insured',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'lives_insured',
      name: 'Lives Insured',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'account_manager',
      name: 'Account Manager',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
      width: 140,
    },
    {
      key: 'last_eligibility_load',
      name: 'Last Eligibility Load',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
  ],

  conversations: [
    {
      key: 'patient',
      name: 'Patient',
      resizable: true,
      size: 300,
      filterable: true,
      sortable: true,
    },
    {
      key: 'care_team_participants',
      name: 'Care Team',
      resizable: true,
      size: 300,
      filterable: true,
      sortable: true,
    },
    {
      key: 'latest_message',
      name: 'Latest Message',
      resizable: true,
    },
    {
      key: 'since_latest_message',
      name: 'Since Latest',
      sortable: true,
      size: 150,
    },
    {
      key: 'latest_message_sent_by',
      name: 'Sent By',
      width: 100,
    },
    {
      key: 'latest_message_seen',
      name: 'Seen',
      width: 45,
    },
    {
      key: 'action_buttons',
      width: 40,
      name: '',
    },
  ],

  hospitals: [
    {
      key: 'product_offering',
      name: 'Type',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
      width: 100,
    },
    {
      key: 'logo',
      name: '',
      width: 80,
      formatter: ImageFormatter,
    },
    {
      key: 'name',
      name: 'Name',
      width: 540,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'address',
      name: 'City/State',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'availability',
      name: 'Availability',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'account_manager',
      name: 'Account Manager',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
      width: 140,
    },
  ],

  physicians: [
    {
      key: 'product_offering_code',
      filterable: true,
      name: 'Type',
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
      width: 100,
    },
    {
      key: 'profile_image',
      name: '',
      width: 47,
      formatter: ImageFormatter,
    },
    {
      key: 'name',
      name: 'Name',
      width: 260,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'hospital',
      name: 'Center of Excellence',
      width: 200,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'npi',
      name: 'Provider NPI',
      width: 200,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'status',
      name: 'Provider Status',
      width: 200,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'phone',
      name: 'Phone',
      width: 200,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'title',
      name: 'Title',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'specialties',
      name: 'Specialties',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'language',
      name: 'Spoken Language(s)',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  offered_procedures: [
    {
      key: 'icon',
      name: '',
      width: 47,
      formatter: ImageFormatter,
    },
    {
      key: 'name',
      name: 'Name',
      width: 240,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'colloquial_name',
      name: 'Colloquial Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'rollups',
      name: 'Rollups',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'procedure_bundles',
      name: 'Procedure Bundles',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'additional_billing_codes',
      name: 'Additional Billing Codes',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  procedure_bundles: [
    {
      key: 'bundle_id',
      name: 'Bundle ID',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'bundle_description',
      name: 'Description',
      filterable: true,
      resizable: true,
    },
    {
      key: 'notes',
      name: 'Notes',
      filterable: true,
      resizable: true,
    },
    {
      key: 'procedure_key',
      name: 'Procedure Key',
      filterRenderer: SingleSelectFilter,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'travel_eligible',
      name: 'Travel Eligible',
      filterable: false,
      sortable: true,
      resizable: true,
    },
  ],

  offered_procedure_rollups: [
    {
      key: 'name',
      name: 'Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'description',
      name: 'Description',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'parent',
      name: 'Parent Rollup',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'offered_procedures',
      name: 'Offered Procedures',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: MultiSelectFilter,
    },
    {
      key: 'released_on',
      name: 'Released On',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'decommissioned_on',
      name: 'Decommissioned On',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'criteria',
      name: 'Criteria',
    },
    {
      key: 'action_buttons',
      width: 80,
      name: '',
    },
  ],

  clinical_contacts: [
    {
      key: 'id',
      name: 'ID',
      width: 60,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'name',
      name: 'Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'email',
      name: 'Email',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'office',
      name: 'Office',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'mobile',
      name: 'Mobile',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'fax',
      name: 'Fax',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  forms: [
    {
      key: 'key',
      name: 'Key',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'name',
      name: 'Name',
      width: 200,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'step_key_id',
      name: 'Step',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'employers',
      name: 'Employers',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'hospitals',
      name: 'Hospitals',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'procedures',
      name: 'Procedures',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'complexity',
      name: 'Complexity',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'started',
      name: 'Started',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'completed',
      name: 'Completed',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'action_buttons',
      width: 80,
      name: '',
    },
  ],

  features: [
    {
      key: 'name',
      name: 'Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  system_jobs: [
    {
      key: 'job_hash',
      name: 'Hash',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'name',
      name: 'Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'client',
      name: 'Client',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'job_source',
      name: 'Source',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'type',
      name: 'Type',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'started_at',
      name: 'Start Time',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'ended_at',
      name: 'End Time',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'running_time',
      name: 'Running Time',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'status',
      name: '',
      width: 50,
    },
  ],

  insurers: [
    {
      key: 'key',
      name: 'Key',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'name',
      name: 'Name',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'tradingPartnerId',
      name: 'Trading Partner ID',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  escalations: [
    {
      key: 'id',
      name: 'ID',
      width: 80,
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'escalation_type',
      name: 'Type',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'description',
      name: 'Description',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'patient',
      name: 'Patient',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'patient_app_referral_uri',
      name: 'Location Path',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'carrier',
      name: 'Carrier',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'client',
      name: 'Client',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'episode_id',
      name: 'Episode',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: NumericFilter,
    },
    {
      key: 'procedure_key',
      name: 'Procedure',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'procedure_mandated',
      name: 'Procedure Mandated',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'owner_ids',
      name: 'Owners',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'escalation_date',
      name: 'Escalation Date',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: DateRangeFilter,
    },
    {
      key: 'exception_status',
      name: 'Exception Status',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'exception_type',
      name: 'Exception Type',
      filterable: true,
      sortable: true,
      resizable: true,
    },
  ],

  round_robins: [
    {
      key: 'avatar',
      name: '',
      width: 50,
    },
    {
      key: 'user_id',
      name: 'User',
      filterable: true,
      sortable: true,
      resizable: true,
      filterRenderer: SingleSelectFilter,
    },
    {
      key: 'employers',
      name: 'Employers',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'procedure_rollups',
      name: 'Procedure Rollups',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'maximum_capacity',
      name: 'Maximum Capacity',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      key: 'action_buttons',
      width: 80,
      name: '',
    },
  ],
};

const tableColumns = { ...defaultTableColumns };

/**
 * Merge the list of values with the data from local storage to use as the
 * initial state in the grid reducer.
 */
Object.keys(tableColumns).forEach((type) => {
  if (!Array.isArray(tableColumns[type])) return;

  tableColumns[type] = tableColumns[type].map((column) => {
    const isHidden = getStoredIsHidden(type, column.key);
    const width = getStoredWidth(type, column.key) || column.width;

    return {
      ...column,
      hidden: isHidden,
      width: isHidden ? 0 : width,
    };
  });
});

export default tableColumns;
