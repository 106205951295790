import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Box } from 'grommet';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import AppErrorsContainer from 'app/containers/AppErrorsContainer';
import HeaderContainer from 'app/containers/HeaderContainer';
import SideMenuContainer from 'app/containers/SideMenuContainer';
import LoginContainer from 'app/containers/LoginContainer';

import loadEpisodesContainer from 'bundle-loader?lazy!app/containers/EpisodesContainer';
import loadEpisodeContainer from 'bundle-loader?lazy!app/containers/EpisodeContainer';
import loadInquiriesContainer from 'bundle-loader?lazy!app/containers/InquiriesContainer';
import loadInquiryContainer from 'bundle-loader?lazy!app/containers/InquiryContainer';
import loadPatientContainer from 'bundle-loader?lazy!app/containers/PatientContainer';
import loadPatientsContainer from 'bundle-loader?lazy!app/containers/PatientsContainer';
import loadComplianceComponent from 'bundle-loader?lazy!app/components/Compliance';
import loadConversationsContainer from 'bundle-loader?lazy!app/containers/ConversationsContainer';
import loadUsersContainer from 'bundle-loader?lazy!app/containers/UsersContainer';
import loadClientsContainer from 'bundle-loader?lazy!app/containers/ClientsContainer';
import loadClientContainer from 'bundle-loader?lazy!app/containers/ClientContainer';
import loadHospitalsContainer from 'bundle-loader?lazy!app/containers/HospitalsContainer';
import loadHospitalContainer from 'bundle-loader?lazy!app/containers/HospitalContainer';
import loadInsurersContainer from 'bundle-loader?lazy!app/containers/InsurersContainer';
import loadInsurerContainer from 'bundle-loader?lazy!app/containers/InsurerContainer';
import loadPhysiciansContainer from 'bundle-loader?lazy!app/containers/PhysiciansContainer';
import loadPhysicianContainer from 'bundle-loader?lazy!app/containers/PhysicianContainer';
import loadProceduresContainer from 'bundle-loader?lazy!app/containers/ProceduresContainer';
import loadProcedureContainer from 'bundle-loader?lazy!app/containers/ProcedureContainer';
import loadFeaturesContainer from 'bundle-loader?lazy!app/containers/FeaturesContainer';
import loadFormsContainer from 'bundle-loader?lazy!app/containers/FormsContainer';
import loadFormComponent from 'bundle-loader?lazy!app/components/Form';
import loadStepsContainer from 'bundle-loader?lazy!app/containers/StepsContainer';
import loadSettingsContainer from 'bundle-loader?lazy!app/containers/SettingsContainer';
import loadRolesContainer from 'bundle-loader?lazy!app/containers/RolesContainer';
import loadSystemJobsContainer from 'bundle-loader?lazy!app/containers/SystemJobsContainer';
import loadProfileContainer from 'bundle-loader?lazy!app/containers/ProfileContainer';
import loadResetPasswordContainer from 'bundle-loader?lazy!app/containers/ResetPasswordContainer';

import Bundle from 'app/components/Common/Bundle';
import AuthorizedRoute from 'app/components/Common/AuthorizedRoute';
import NoMatch from 'app/components/Common/NoMatch';
import Spinner from 'app/components/Common/Core/Spinner';
import { capitalizeWords, setDocumentTitle } from 'app/utils/methods';

import {
  AppBox,
  AppFullWidthBox,
  AppLoginBox,
  withGlobalStyles,
} from './styles';

class App extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    sessionLoading: PropTypes.bool.isRequired,
    isExpirationMessageShown: PropTypes.bool.isRequired,
    initialLoadActive: PropTypes.bool.isRequired,
    sessionLoad: PropTypes.func.isRequired,
    loginFromSession: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.setTitle();

    this.props.loginFromSession();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname === this.props.location.pathname) return;

    window.scrollTo(0, 0);

    this.setTitle();
  }

  setTitle() {
    const parts = this.props.location.pathname.split('/');
    parts.unshift();
    setDocumentTitle(parts.map(capitalizeWords).join(' '));
  }

  render() {
    if (this.props.initialLoadActive) {
      return (
        <Box flex height="100vh" justify="center" align="center">
          <Spinner />
        </Box>
      );
    }

    // render login screen if user was not logged in during the initial load process
    if (!this.props.isAuthenticated) {
      return (
        <AppBox noHeader>
          <AppErrorsContainer />

          <AppLoginBox centered>
            <Switch>
              <Route path="/password-reset">
                <Bundle load={loadResetPasswordContainer}>
                  {(Comp) => (Comp ? <Comp /> : <div />)}
                </Bundle>
              </Route>

              <Route>
                <LoginContainer />
              </Route>
            </Switch>
          </AppLoginBox>
        </AppBox>
      );
    }

    // else load app if user is logged in
    return (
      <AppBox>
        <AppErrorsContainer />

        <AppFullWidthBox>
          <HeaderContainer />
          <SideMenuContainer />
        </AppFullWidthBox>

        <AppFullWidthBox>
          <Switch>
            <Redirect
              exact
              from="/"
              to={
                this.props.features.includes('episodes:view')
                  ? '/episodes'
                  : '/users'
              }
            />

            <AuthorizedRoute permission="features:edit" exact path="/features">
              <Bundle load={loadFeaturesContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="roles:edit" exact path="/roles">
              <Bundle load={loadRolesContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="episodes:view" exact path="/episodes">
              <Bundle load={loadEpisodesContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="episodes:view"
              path="/episodes/:episodeId"
            >
              <Bundle load={loadEpisodeContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute permission="episodes:view" exact path="/inquiries">
              <Bundle load={loadInquiriesContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="episodes:view"
              path="/inquiries/:inquiryId"
            >
              <Bundle load={loadInquiryContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute
              permission="system-jobs:view"
              exact
              path="/system-jobs"
            >
              <Bundle load={loadSystemJobsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="patients:view" exact path="/patients">
              <Bundle load={loadPatientsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="patients:view"
              path="/patients/:patientId"
            >
              <Bundle load={loadPatientContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute
              exact
              path="/conversations"
              permission="conversations:view"
            >
              <Bundle load={loadConversationsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="users:view" exact path="/users">
              <Bundle load={loadUsersContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="clients:view" exact path="/clients">
              <Bundle load={loadClientsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="clients:view"
              path="/clients/:clientId"
            >
              <Bundle load={loadClientContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute
              permission="hospitals:view"
              exact
              path="/hospitals"
            >
              <Bundle load={loadHospitalsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="hospitals:view"
              path="/hospitals/:hospitalId"
            >
              <Bundle load={loadHospitalContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="insurers:view" exact path="/insurers">
              <Bundle load={loadInsurersContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="insurers:view"
              exact
              path="/insurers/:insurerId"
            >
              <Bundle load={loadInsurerContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute
              permission="physicians:view"
              exact
              path="/physicians"
            >
              <Bundle load={loadPhysiciansContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="physicians:view"
              path="/physicians/:physicianId"
            >
              <Bundle load={loadPhysicianContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute
              permission="procedures:view"
              exact
              path="/procedures"
            >
              <Bundle load={loadProceduresContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="procedures:view"
              path="/procedures/:procedureId"
            >
              <Bundle load={loadProcedureContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="forms:view" exact path="/forms">
              <Bundle load={loadFormsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>
            <AuthorizedRoute
              permission="forms:view"
              exact
              path="/forms/:formId"
            >
              <Bundle load={loadFormComponent}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="steps:view" exact path="/steps">
              <Bundle load={loadStepsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute permission="compliance:view" path="/compliance">
              <Bundle load={loadComplianceComponent}>
                {(Comp) =>
                  Comp ? <Comp itemType="" recordType="" /> : <div />
                }
              </Bundle>
            </AuthorizedRoute>

            <AuthorizedRoute
              permission="care-app:settings:edit"
              exact
              path="/settings"
            >
              <Bundle load={loadSettingsContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </AuthorizedRoute>

            <Route exact path="/profile">
              <Bundle load={loadProfileContainer}>
                {(Comp) => (Comp ? <Comp /> : <div />)}
              </Bundle>
            </Route>

            <Route path="/login">
              <Redirect to="/" />
            </Route>

            <Route>
              <NoMatch />
            </Route>
          </Switch>

          {(this.props.isExpirationMessageShown ||
            this.props.reauthenticate) && (
            <LoginContainer
              modal
              heading={
                this.props.isExpirationMessageShown
                  ? 'Your session has expired. Please login again with your Carrum Health credentials.'
                  : 'Your session is about to expire.  Please login again with your Carrum Health credentials.'
              }
            />
          )}
        </AppFullWidthBox>
      </AppBox>
    );
  }
}

export default withRouter(withGlobalStyles(App));
