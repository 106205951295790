/**
 * Display a global error message to users.
 *
 * @param {object|string} error An error object or string.
 */
export function showGlobalError(error) {
  return (dispatch) => {
    if (error && error.response && error.response.status === 401) return;

    const defaultError =
      'Oops!  Something went wrong.  If this error continues, please log a bug ticket with Engineering.';

    let errorMessage = error;

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      errorMessage = error.response.data.message.split('\n')[0];
    } else if (error && error.message) {
      errorMessage = error.message;
    }

    return dispatch(receiveGlobalError(errorMessage || defaultError));
  };
}

export function toggleSideMenu(sideMenuOpen) {
  return {
    type: 'TOGGLE_SIDE_MENU',
    sideMenuOpen,
  };
}

export function toggleConversationList(status) {
  return {
    type: 'TOGGLE_CONVERSATION_LIST',
    status,
  };
}

export function setInitialLoad(initialLoadActive) {
  return {
    type: 'SET_INITIAL_LOAD',
    initialLoadActive,
  };
}

export function receiveGlobalError(error) {
  return {
    type: 'RECEIVE_GLOBAL_ERROR',
    error,
  };
}

export function dismissGlobalError(id) {
  return {
    type: 'REMOVE_GLOBAL_ERROR',
    id,
  };
}
