import merge from 'lodash/merge';

const nullState = Object.freeze({
  sideMenuOpen: false,
  collapsedConversations: [],
  openConversations: [],
  openReceivedConversation: false,
  initialLoadActive: true,
  errors: [],
  conversationListOpen: false,
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_LOGOUT':
      return {
        ...nullState,
        initialLoadActive: false,
      };
    case 'SET_INITIAL_LOAD':
      return merge({}, state, { initialLoadActive: action.initialLoadActive });
    case 'RECEIVE_GLOBAL_ERROR': {
      const error = { message: action.error, id: String(Math.random()) };
      return { ...state, errors: [...state.errors, error] };
    }
    case 'REMOVE_GLOBAL_ERROR': {
      const errors = [...state.errors];
      const index = errors.findIndex(({ id }) => id === action.id);
      errors.splice(index, 1);

      return { ...state, errors };
    }
    case 'TOGGLE_SIDE_MENU':
      if (action.sideMenuOpen === undefined) {
        return merge({}, state, { sideMenuOpen: !state.sideMenuOpen });
      } else {
        return merge({}, state, { sideMenuOpen: action.sideMenuOpen });
      }
    case 'TOGGLE_CONVERSATION_LIST':
      if (typeof action.status === 'boolean')
        return {
          ...state,
          conversationListOpen: action.status,
        };

      return {
        ...state,
        conversationListOpen: !state.conversationListOpen,
      };
    case 'OPEN_NEXT_RECEIVED_CONVERSATION':
      return {
        ...state,
        openReceivedConversation: action.status,
      };
    case 'OPEN_CONVERSATION_PANEL': {
      const index = state.openConversations.indexOf(action.id);

      if (index > -1) return state;

      return {
        ...state,
        openConversations: [action.id, ...state.openConversations],
      };
    }
    case 'CLOSE_CONVERSATION_PANEL': {
      const index = state.openConversations.indexOf(action.id);

      if (index === -1) return state;

      return {
        ...state,
        openConversations: [
          ...state.openConversations.slice(0, index),
          ...state.openConversations.slice(index + 1),
        ],
      };
    }
    case 'COLLAPSE_CONVERSATION_PANEL': {
      const index = state.collapsedConversations.indexOf(action.id);

      if (index > -1) return state;

      return {
        ...state,
        collapsedConversations: [...state.collapsedConversations, action.id],
      };
    }
    case 'EXPAND_CONVERSATION_PANEL': {
      const index = state.collapsedConversations.indexOf(action.id);

      if (index === -1) return state;

      return {
        ...state,
        collapsedConversations: [
          ...state.collapsedConversations.slice(0, index),
          ...state.collapsedConversations.slice(index + 1),
        ],
      };
    }
    default:
      return state;
  }
};
