import { formatData, replaceAtIndex } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  steps: [],
  activeStep: null,
  stepKeys: [],
});

export { nullState };

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_STEPS':
      return { ...state, steps: action.steps.map(formatData) };
    case 'RECEIVE_STEP': {
      const step = formatData(action.step);
      const idx = state.steps.findIndex(({ order }) => order === step.order);
      return {
        ...state,
        steps: replaceAtIndex(state.steps, step, idx),
      };
    }
    case 'RECEIVE_ACTIVE_STEP':
      return { ...state, activeStep: action.step };
    case 'RECEIVE_EPISODE':
      return {
        ...state,
        activeStep: state.activeStep || formatData(action.episode).currentStep,
      };
    case 'RECEIVE_STEP_KEYS':
      return { ...state, stepKeys: action.stepKeys.map(formatData) };
    case 'RECEIVE_STEP_KEY': {
      const stepKeys = [...state.stepKeys];
      const stepKey = formatData(action.stepKey);
      const index = stepKeys.findIndex(({ order }) => order === stepKey.order);

      if (index !== -1) stepKeys[index] = stepKey;

      return { ...state, stepKeys };
    }
    default:
      return state;
  }
};
