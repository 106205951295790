import styled from 'styled-components';
import { Button as GrommetButton } from 'grommet';
import theme from 'app/theme';

const brand = theme.global.colors.brand;
const dark1 = theme.global.colors['dark-1'];
const white = theme.global.colors.white;

/**
 * TEMP: The styling for Grommet v1 overrides some of Grommet v2 button
 * styling. This component implements the Grommet v2 styling so that it
 * overrides the Grommet v1 stylesheet.
 *
 * TODO: Remove this component after Grommet v1 is removed, in favor of just
 * importing the Grommet v2 Button component.
 */
export const Button = styled(GrommetButton)`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  align-items: center;
  border-radius: 18px !important;
  min-width: 1px !important;
  padding: ${({ plain }) => (plain ? '0 !important' : '4px 22px !important')};
  font-size: ${({ fontSize }) =>
    fontSize ? `${fontSize}px !important` : '18px !important'};
  line-height: 24px !important;
  color: ${({ primary }) =>
    primary ? `${white} !important` : `${dark1} !important`};
  background: ${({ primary }) =>
    primary ? `${brand} !important` : 'transparent !important'};
  border: ${({ plain }) =>
    plain ? 'none !important' : `2px solid ${brand} !important`};
  outline-width: 0 !important;
  border-color: none !important;
  box-shadow: none !important;
  cursor: ${({ disabled }) => (disabled ? 'default !important' : 'pointer')};
  height: ${({ height }) => (height === 'auto' ? 'max-content' : '')};
  width: ${({ width }) => (width === 'auto' ? 'max-content' : '')};

  &:hover {
    box-shadow: ${({ plain, disabled }) =>
      plain || disabled
        ? 'none !important'
        : `0px 0px 0px 2px ${brand} !important`};
  }
`;

Button.displayName = 'Button';

export default Button;
