import { fetchUserCurrentEpisode } from 'app/actions/episodeActions';
import { fetchPatientByUserId } from 'app/actions/patientActions';
import { openNewTab } from 'app/utils/methods';

/**
 * Sets up a conversation to be opened in a new
 * episode tab if the episodeId param is present.
 * If not present, attempts to fetch the current
 * episode for the user. Opens the conversation
 * in the patient details page if no episode exists.
 * @param {string} user      user for the episode
 * @param {string} episodeId identifier for the user's episode (optional)
 */
export function openConversation(user, episodeId) {
  return async (dispatch) => {
    if (episodeId)
      return openNewTab(
        `${window.location.origin}/episodes/${episodeId}/messages`
      );

    try {
      const resp = await dispatch(fetchUserCurrentEpisode(user.id));
      episodeId = resp.data.id;
    } catch (e) {
      // Do nothing
    }

    if (episodeId)
      return openNewTab(
        `${window.location.origin}/episodes/${episodeId}/messages`
      );

    const patient = await dispatch(fetchPatientByUserId(user.id));

    if (patient)
      return openNewTab(
        `${window.location.origin}/patients/${patient.id}/messages`
      );
  };
}
