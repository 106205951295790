import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  procedures: [],
  procedure: null,
  procedureBundles: [],
  procedureBundle: null,
  procedureRollups: [],
  procedureRollupsLoading: false,
  loading: false,
});

export default (state = nullState, action) => {
  switch (action.type) {
    case 'RECEIVE_PROCEDURES': {
      const newProcedures = action.procedures.map(formatData);
      const procedures = action.append
        ? [...state.procedures, ...newProcedures]
        : newProcedures;
      return { ...state, procedures };
    }
    case 'RECEIVE_PROCEDURE':
      return {
        ...state,
        procedure: formatData(action.procedure),
        loading: false,
      };
    case 'RECEIVE_PROCEDURE_LOADING':
      return { ...state, loading: action.procedureLoading };
    case 'RECEIVE_PROCEDURE_BUNDLES': {
      const newProcedureBundles = action.procedureBundles.map(formatData);
      const procedureBundles = action.append
        ? [...state.procedureBundles, ...newProcedureBundles]
        : newProcedureBundles;
      return { ...state, procedureBundles };
    }
    case 'RECEIVE_PROCEDURE_ROLLUPS': {
      const newProcedureRollups = action.procedureRollups.map(formatData);
      const procedureRollups = action.append
        ? [...state.procedureRollups, ...newProcedureRollups]
        : newProcedureRollups;
      return { ...state, procedureRollups };
    }
    case 'RECEIVE_PROCEDURE_BUNDLE':
      return {
        ...state,
        procedureBundle: formatData(action.procedureBundle.data),
        loading: false,
      };
    case 'RECEIVE_PROCEDURE_BUNDLE_LOADING':
      return { ...state, loading: action.procedureBundleLoading };
    case 'RECEIVE_PROCEDURE_ROLLUPS_LOADING':
      return {
        ...state,
        procedureRollupsLoading: action.procedureRollupsLoading,
      };
    default:
      return state;
  }
};
