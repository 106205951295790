import camelCase from 'camelcase';
import { formatData } from 'app/utils/reducerUtils';
import { uniqBy } from 'lodash';

export const nullState = Object.freeze({
  user: {},
  users: [],
  customerSuccess: [],
  careConcierges: [],
  providerOps: [],
  loading: false,
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_CARE_CONCIERGES': {
      return {
        ...state,
        careConcierges: action.careConcierges.map(formatData),
      };
    }
    case 'RECEIVE_USER':
      return { ...state, user: formatData(action.user) };
    case 'RECEIVE_USERS':
      return {
        ...state,
        users: action.users.map(formatData),
      };
    case 'RECEIVE_ADDITIONAL_USERS':
      return {
        ...state,
        users: uniqBy([...state.users, ...action.users.map(formatData)], 'id'),
      };
    case 'RECEIVE_USERS_BY_ROLE':
      return {
        ...state,
        [camelCase(action.role)]: action.users.map(formatData),
      };
    case 'RECEIVE_USER_LOADING':
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};
