import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, FormField, Layer, Text, TextInput } from 'grommet';

import { Close } from 'grommet-icons';

/**
 * Parses a date string for comparison.
 *
 * @param {string} dateString - a date string from the <DateTime /> component.
 * @return {date} a native Date instance.
 */
const asDate = (dateString) => new Date(Date.parse(dateString));

/**
 * Renders an input for handling date range filtering.
 */
export default class DateRangeFilter extends Component {
  static propTypes = {
    column: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      startDate: '',
      endDate: '',
      originalStartDate: '',
      originalEndDate: '',
    };
  }

  /**
   * Returns true if a field is empty or if the selected
   * date range is invalid.
   */
  isDisabled = () => {
    return (
      !this.state.startDate ||
      !this.state.endDate ||
      asDate(this.state.startDate) > asDate(this.state.endDate)
    );
  };

  /**
   * Clears the current selection and hides the layer.
   */
  onClear = () => {
    this.setState({ isOpen: false, startDate: '', endDate: '' });
    this.props.onChange({ filterTerm: '', column: this.props.column });
  };

  /**
   * Stores the current form values and displays the layer.
   */
  onOpen = () => {
    this.setState({
      isOpen: true,
      originalStartDate: this.state.startDate,
      originalEndDate: this.state.endDate,
    });
  };

  /**
   * Resets the form values to their previous state and
   * hides the layer.
   */
  onClose = () => {
    this.setState({
      isOpen: false,
      startDate: this.state.originalStartDate,
      endDate: this.state.originalEndDate,
    });
  };

  /**
   * If the form is valid, hides the layer and calls
   * `this.props.onChange()` with the selections.
   *
   * @param {object} event - a native click event.
   */
  onSubmit = (event) => {
    event.preventDefault();

    const { startDate, endDate } = this.state;

    if (!this.isDisabled()) {
      this.setState({ isOpen: false });
      this.props.onChange({
        filterTerm: { startDate, endDate },
        column: this.props.column,
      });
    }
  };

  render() {
    const error =
      this.state.startDate &&
      this.state.endDate &&
      asDate(this.state.startDate) > asDate(this.state.endDate)
        ? 'The end date must be after the start date.'
        : null;

    return (
      <div className="DateFilter">
        <input
          placeholder="Start - End"
          value={
            this.state.startDate && this.state.endDate
              ? `${this.state.startDate} - ${this.state.endDate}`
              : ''
          }
          onClick={this.onOpen}
          onChange={() => {}}
        />

        {this.state.isOpen && (
          <Layer
            onClickOutside={this.onClose}
            className="DateFilter-layer"
            align="center"
          >
            <Box width="medium" pad="medium" gap="medium">
              <Box align="center" direction="row" justify="between">
                <Text align="start" size="large">
                  Select a Date Range
                </Text>

                <Button icon={<Close />} onClick={this.onClose} />
              </Box>

              <Box justify="between" direction="column">
                <FormField label="Start Date">
                  <TextInput
                    placeholder="MM/DD/YYYY"
                    value={this.state.startDate}
                    onChange={({ target }) =>
                      this.setState({ startDate: target.value })
                    }
                  />
                </FormField>
                <FormField label="End Date" error={error}>
                  <TextInput
                    placeholder="MM/DD/YYYY"
                    value={this.state.endDate}
                    onChange={({ target }) =>
                      this.setState({ endDate: target.value })
                    }
                  />
                </FormField>
              </Box>

              <Box direction="row" justify="between" align="center">
                <Button label="Clear Dates" onClick={this.onClear} />
                <Button
                  primary
                  label="Apply Dates"
                  onClick={this.onSubmit}
                  disabled={this.isDisabled()}
                />
              </Box>
            </Box>
          </Layer>
        )}
      </div>
    );
  }
}
