import {
  formatData,
  replaceAtIndex,
  removeAtIndex,
  addAtEnd,
} from 'app/utils/reducerUtils';

import { mergeConversations } from 'app/utils/methods';

const nullState = Object.freeze({
  userStatuses: {},
  conversations: [],
  connected: {},
  progress: null,
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_LOGOUT':
      return nullState;
    case 'RECEIVE_USER_STATUSES':
      return {
        ...state,
        userStatuses: {
          ...state.userStatuses,
          ...formatObjects(action.userStatuses, 'userId'),
        },
      };
    case 'RECEIVE_USER_STATUS': {
      const status = formatObject(action.userStatus, 'userId');

      return {
        ...state,
        userStatuses: {
          ...state.userStatuses,
          ...status,
        },
      };
    }
    case 'RECEIVE_CONVERSATIONS': {
      const progress = action.progress || state.progress;
      const conversations = [...action.conversations]
        .map(formatData)
        .map((convo) => ({
          ...convo,
          messages: convo.messages ? convo.messages.reverse() : [],
          hasMoreMessages: Boolean(
            convo.messages &&
              convo.messages.length &&
              convo.messages.length % 20 === 0
          ),
        }));

      return {
        ...state,
        progress,
        conversations: mergeConversations(state.conversations, conversations),
      };
    }
    case 'RECEIVE_CONVERSATION': {
      const conversation = formatData(action.conversation);
      conversation.messages = conversation.messages
        ? conversation.messages.reverse()
        : [];
      conversation.hasMoreMessages = Boolean(
        conversation.messages.length && conversation.messages.length % 20 === 0
      );

      return {
        ...state,
        conversations: mergeConversations(state.conversations, [conversation]),
      };
    }
    case 'DELETE_CONVERSATION': {
      const index = state.conversations.findIndex(({ id }) => id === action.id);

      return {
        ...state,
        conversations: removeAtIndex(state.conversations, index),
      };
    }
    case 'RECEIVE_MESSAGES': {
      let messages = action.messages.map(formatData).reverse();

      const convoIndex = state.conversations.findIndex(
        ({ id }) => id == action.conversationId
      );

      let conversation = state.conversations[convoIndex];

      conversation = {
        ...conversation,
        messages: [...messages, ...conversation.messages],
        hasMoreMessages: messages.length === 20,
      };

      return {
        ...state,
        conversations: replaceAtIndex(
          state.conversations,
          conversation,
          convoIndex
        ),
      };
    }
    case 'RECEIVE_MESSAGE': {
      const message = formatData(action.message);
      const { conversationId } = message;
      const convoIndex = state.conversations.findIndex(
        ({ id }) => id == conversationId
      );

      let conversation = state.conversations[convoIndex];
      if (!conversation) return state;

      let messages = conversation.messages;

      const msgIndex = messages.findIndex(({ id }) => id == message.id);

      if (msgIndex > -1) {
        messages = replaceAtIndex(messages, message, msgIndex);
      } else {
        messages = addAtEnd(messages, message);
      }

      if (!message.pending)
        messages = messages.filter(({ pending }) => !pending);

      conversation = {
        ...conversation,
        messages,
      };

      return {
        ...state,
        conversations: [
          conversation,
          ...state.conversations.slice(0, convoIndex),
          ...state.conversations.slice(convoIndex + 1),
        ],
      };
    }
    case 'RECEIVE_CONNECTION_STATUS':
      return {
        ...state,
        connected: { ...state.connected, ...action.connected },
      };
    default:
      return state;
  }
};

function formatObjects(items, identifier) {
  if (!Object.keys(items.length)) return {};

  return items.reduce((obj, item) => {
    item = formatData(item);

    obj[item[identifier]] = item;

    return obj;
  }, {});
}

function formatObject(item, identifier) {
  item = formatData(item);

  return {
    [item[identifier]]: item,
  };
}
