import { css } from 'styled-components';
import theme from 'app/theme';

const { lightGrey } = theme.global.colors;

export default css`
  html,
  body {
    margin: 0;
  }

  .taller-form-field {
    padding: 10px 22px !important;
  }

  .grommetux-layer__container {
    display: block !important;
  }

  .grommet {
    &.grommetux-drop {
      z-index: 99999 !important;
    }
  }

  .full-width-box {
    width: 100%;
  }

  div[role='tablist'] {
    width: 100%;
  }

  .Sidebar {
    margin-bottom: 1.5rem;
  }

  .grommetux-list {
    > li {
      padding-left: 0;
      .secondary {
        margin-left: 1rem;
      }
      p {
        margin: 0;
        transition: all 0s ease-in-out;
      }
      svg {
        transition: all 0s ease-in-out;
      }
    }
  }

  .document-table {
    > table {
      margin-bottom: 0;
    }
  }

  .card-list {
    margin-left: 0rem;
    list-style: none;
    li {
      margin-bottom: 0.5rem;
      svg {
        margin-right: 0.5rem;
      }
      p {
        margin: 0;
      }
    }
  }

  .grommetux-box--width-full {
    width: 100%;
  }

  .hover-link-with-icon {
    .grommetux-button__label {
      margin-right: 0 !important;
    }
  }

  .horizontal-form {
    width: 100% !important;
    .grommetux-form-field {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  .full-width-form {
    width: 100% !important;
  }

  .button-td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    button[type='submit'] {
      margin-top: 10px;
    }
  }

  .Entry {
    > div {
      padding-top: 0;
    }
  }

  .icon-before {
    margin-right: 1rem;
  }
  .icon-after {
    margin-right: 1rem;
  }

  .table-row-align-top {
    vertical-align: top;
  }
  .table-row-input {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 8px 7px !important;
  }
  .table-row-select-input {
    > input {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      padding: 8px 0 8px 7px !important;
    }
  }
  .table-row-input-xxxxsmall {
    width: 2rem;
    > input {
      width: 2rem;
    }
  }
  .table-row-input-xxxsmall {
    width: 2.5rem;
    > input {
      width: 2.5rem;
    }
  }
  .table-row-input-xxsmall {
    width: 3.25rem;
    > input {
      width: 3.25rem;
    }
  }
  .table-row-input-xsmall {
    width: 4.25rem;
    > input {
      width: 4.25rem;
    }
  }
  .table-row-input-small {
    width: 6rem;
    > input {
      width: 6rem;
    }
  }
  .table-row-input-medium-small {
    width: 7rem;
    > input {
      width: 7rem;
    }
  }
  .table-row-input-medium {
    width: 8rem;
    > input {
      width: 8rem;
    }
  }
  .table-row-input-large {
    width: 10rem;
    > input {
      width: 10rem;
    }
  }

  .flex-align-center {
    display: flex;
    align-items: center;
  }

  .flex-form-field {
    .grommetux-form-field__contents {
      display: flex;
    }
  }

  .button-min-icon-padding {
    .grommetux-button__icon {
      padding: 0 0.5rem 0 0 !important;
    }
  }

  .editable-field-label {
    line-height: 28px;
    margin-right: 5px;
  }

  .editable-field-value {
    line-height: 28px;
  }

  .react-tags__search {
    position: relative;
    margin-bottom: 8px;
  }

  .react-tags__search-input {
    border: solid 1px rgba(0, 0, 0, 0.33) !important;
    border-radius: 4px !important;
    font-size: inherit !important;
    padding: 11px;
    position: relative;
    width: calc(100% - 20px) !important;

    &:active,
    &:focus {
      box-shadow: 0 0 2px 2px #6fffb0;
      border: solid 1px #6fffb0 !important;
      outline: none !important;
    }
  }

  .react-tags__suggestions {
    position: absolute;
    width: 100%;
    opacity: 0.9;
    z-index: 1;
    overflow-y: scroll;
    max-height: 150px;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      border-radius: 2px;
    }

    li {
      margin: 0;
      padding: 8px;
      background-color: #eee;
      text-align: left;
      max-width: 100% !important;
      cursor: pointer;

      &:hover {
        background-color: ${lightGrey};
      }
    }
  }

  .react-tags__selected-tag {
    border: 1px solid #ccc !important;
    cursor: pointer;
    border-radius: 2px !important;
    padding: 10px 10px 10px 14px !important;
    margin: 0 8px 8px 0 !important;
    font-size: 1em !important;
    font-weight: 500 !important;
    display: inline-block;
    position: relative;
  }

  .grommetux-form-field {
    &.grommetux-form-field--overflow {
      overflow: visible;
    }

    .react-tags {
      margin: 10px 20px 0;
    }

    .react-tags__search-input {
      border: none !important;
    }
  }

  .rdw-link-modal {
    .rdw-link-modal-buttonsection {
      display: flex;
      flex-direction: row;

      .rdw-link-modal-btn {
        display: flex;
        width: initial;
        min-width: 0;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
`;
