import { formatData, parsePagination } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  notes: [],
  recommendedTags: [],
  pagination: {},
  loading: false,
});

export { nullState };

export default (state = nullState, action) => {
  switch (action.type) {
    case 'RECEIVE_NOTES_LOADING':
      return { ...state, loading: action.loading };
    case 'RECEIVE_NOTES': {
      const { data, links } = action.notes;
      const pagination = links ? parsePagination(links) : {};

      return {
        ...state,
        notes: data.map(formatData),
        pagination,
      };
    }
    case 'RECEIVE_NOTE': {
      const notes = [].concat(state.notes || []);
      const note = formatData(action.note);
      const index = notes
        .map(({ id }) => parseInt(id, 10))
        .indexOf(parseInt(note.id, 10));

      if (index !== -1) {
        notes[index] = note;
      } else {
        notes.unshift(note);
      }

      return { ...state, notes };
    }
    case 'REMOVE_NOTE': {
      const notes = [].concat(state.notes || []);
      const note = formatData(action.note);
      const index = notes
        .map(({ id }) => parseInt(id, 10))
        .indexOf(parseInt(note.id, 10));

      if (index !== -1) {
        notes.splice(index, 1);
      }

      return { ...state, notes };
    }
    case 'RECEIVE_RECOMMENDED_TAGS': {
      const recommendedTags = action.merge
        ? [...state.recommendedTags, ...action.tags.map(formatData)]
        : action.tags.map(formatData);
      return { ...state, recommendedTags };
    }
    default:
      return state;
  }
};
