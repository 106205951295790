import axios from 'axios';
import { uniq } from 'lodash';

import { receiveCurrentUser } from 'app/actions/sessionActions';
import { showGlobalError } from 'app/actions/uiActions';
import { getToken } from 'app/selectors/session';
import { digest } from 'app/utils/constants';
import { generateEligibilityDigest } from 'app/utils/methods';
import { unformatObject } from 'app/utils/reducerUtils';

export function createCoreUser(params) {
  return async function (dispatch, getState) {
    try {
      const { firstName, lastName, employerRegistrationId, dateOfBirth } =
        params;

      const eligibilityDigest = generateEligibilityDigest(
        {
          firstName,
          lastName,
          employerRegistrationId,
          dateOfBirth,
        },
        digest
      );

      const user = unformatObject({
        ...params,
        eligibilityDigest,
        mobileRegistration: false,
      });

      dispatch(receiveUserLoading(true));

      const response = await axios.post(
        `${CORE_API_URL}/users`,
        { user, redirect_base_url: `${window.location.origin}/` },
        { headers: { Authorization: `Bearer ${getToken(getState())}` } }
      );

      dispatch(receiveUser(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
      throw error;
    } finally {
      dispatch(receiveUserLoading(false));
    }
  };
}

export function updateUser(userId, params) {
  return async function (dispatch, getState) {
    const {
      session: { coreServiceToken },
    } = getState();

    return axios.patch(
      `${CORE_API_URL}/users/${userId}`,
      { user: unformatObject(params) },
      {
        headers: {
          Authorization: `Bearer ${coreServiceToken}`,
        },
      }
    );
  };
}

export function updateCurrentUser(params) {
  return async (dispatch, getState) => {
    try {
      const { id } = getState().session.currentUser;
      const response = await dispatch(updateUser(id, params));

      dispatch(receiveCurrentUser(response.data.data));
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

/**
 * Fetches a user from the Core Service API,
 * merges the profile data with a given params
 * object, and then updates the user.
 *
 * @param {string} userId The ID of the user to update.
 * @param {object} params An object with profile params to update.
 * @return {promise} A promise that resolves after updating the profile information.
 */
export function updateUserProfile(userId, params) {
  return async (dispatch) => {
    try {
      const response = await dispatch(fetchCoreUser(userId));
      const { profile } = unformatObject(response.data);

      await dispatch(
        updateUser(userId, { profile: { ...profile, ...params } })
      );
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export const fetchUsersFromUserIds = () => (dispatch, getState) => {
  const { records, type } = getState().grid;

  let listOfUserIds = [];
  if (type === 'escalations') {
    listOfUserIds = uniq(
      records.reduce(
        (ownerIds, escalation) => [...ownerIds, ...escalation.ownerIds],
        []
      )
    );
  }
  if (type === 'paper_trails') {
    listOfUserIds = uniq(
      records.reduce((userIds, user) => [...userIds, user.accessedBy], [])
    );
  }

  return dispatch(fetchUsersById(listOfUserIds));
};

export const fetchUsersById =
  (listOfUserIds = []) =>
  (dispatch) => {
    if (!listOfUserIds.length) {
      dispatch(receiveUsers([]));
    } else {
      dispatch(
        fetchUsers({
          'filter[id]': listOfUserIds.join(','),
        })
      );
    }
  };

export const fetchUserByName = (name) => (dispatch, getState) =>
  axios
    .get(`${CORE_API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${getState().session.coreServiceToken}`,
      },
      params: {
        'filter[name]': name,
      },
    })
    .then((resp) => dispatch(receiveAdditionalUsers(resp.data.data)))
    .catch((error) => dispatch(showGlobalError(error)));

export const fetchUsers = (params) => (dispatch, getState) =>
  axios
    .get(`${CORE_API_URL}/users`, {
      headers: {
        Authorization: `Bearer ${getState().session.coreServiceToken}`,
      },
      params,
    })
    .then((resp) => dispatch(receiveUsers(resp.data.data)))
    .catch((error) => dispatch(showGlobalError(error)));

export function fetchCoreUser(userId) {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios.get(`${CORE_API_URL}/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

export function fetchCareUser(userId) {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios.get(`${CARE_API_URL}/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

export function fetchCareConcierges() {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios
      .get(`${CORE_API_URL}/roles/care_concierge/users`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { 'page[size]': 1000, sort: 'name' },
      })
      .then((response) => dispatch(receiveCareConcierges(response.data.data)))
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

export function fetchUsersByRole(role) {
  return function (dispatch, getState) {
    const token = getState().session.coreServiceToken;

    return axios
      .get(`${CORE_API_URL}/roles/${role}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) =>
        dispatch(receiveUsersByRole(role, response.data.data))
      )
      .catch((error) => dispatch(showGlobalError(error)));
  };
}

/**
 * Logs in as another user.
 * @param {object} userId The ID of the user to impersonate
 */
export function impersonateUser(userId) {
  return async (dispatch, getState) => {
    try {
      const { coreServiceToken } = getState().session;
      const response = await axios.post(
        `${CORE_API_URL}/users/${userId}/impersonate`,
        {},
        {
          headers: { Authorization: `Bearer ${coreServiceToken}` },
        }
      );

      window.document.cookie =
        `carrumoverride=${response.data.jwt};` +
        `expires=${new Date(new Date().getTime() + 10000).toUTCString()};` +
        'domain=carrumhealth.com;' +
        'path=/;secure';

      window.open(response.data.redirect);
    } catch (error) {
      dispatch(showGlobalError(error));
    }
  };
}

export function receiveUser(user) {
  return {
    type: 'RECEIVE_USER',
    user,
  };
}

export function receiveUsers(users) {
  return {
    type: 'RECEIVE_USERS',
    users,
  };
}

export const receiveAdditionalUsers = (users) => ({
  type: 'RECEIVE_ADDITIONAL_USERS',
  users,
});

export function receiveCareConcierges(careConcierges) {
  return {
    type: 'RECEIVE_CARE_CONCIERGES',
    careConcierges,
  };
}

export function receiveUsersByRole(role, users) {
  return {
    type: 'RECEIVE_USERS_BY_ROLE',
    role,
    users,
  };
}

export function receiveUserLoading(loading) {
  return {
    type: 'RECEIVE_USER_LOADING',
    loading,
  };
}
