import { connect } from 'react-redux';

import AppErrors from 'app/components/App/AppErrors';
import { dismissGlobalError } from 'app/actions/uiActions';

const mapStateToProps = ({ ui }) => ({
  errors: ui.errors,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: (id) => dispatch(dismissGlobalError(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppErrors);
