import React from 'react';

import { Button } from 'grommet';
import PropTypes from 'prop-types';

import triangle from 'app/assets/img/triangle-grey.svg';
import theme from 'app/theme';

import { MenuItemBox, CountBox, Img } from './styles';

const propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,

  active: PropTypes.bool,
  icon: PropTypes.object,
};

const MenuItem = ({ active, count, icon, onClick }) => {
  return (
    <MenuItemBox>
      <Img hide={!active} src={triangle} alt="triangle pointer" />

      <Button icon={icon} onClick={onClick} />

      {count > 0 && (
        <CountBox
          align="center"
          badgeColor={theme.global.colors.purple}
          justify="center"
        >
          <span>{count}</span>
        </CountBox>
      )}
    </MenuItemBox>
  );
};

MenuItem.propTypes = propTypes;

export default MenuItem;
