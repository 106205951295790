import { Box } from 'grommet';

import styled from 'styled-components';

export const AnimatedBox = styled(Box)`
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: ${({ translateY }) => `translateY(${translateY}px)`};
  transition: ${({ animateFor }) => `transform ${animateFor}ms ease-in-out`};
  z-index: 999999;
`;
