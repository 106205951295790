import { formatData } from 'app/utils/reducerUtils';

const nullState = Object.freeze({
  clients: [],
  client: null,
  loading: false,
  procedures: [],
  proceduresLoading: false,
  providers: [],
});

export default (state = nullState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case 'RECEIVE_CLIENTS': {
      const newClients = action.clients.map(formatData);
      const clients = action.append
        ? [...state.clients, ...newClients]
        : newClients;

      return { ...state, clients };
    }
    case 'RECEIVE_CLIENT': {
      const client = formatData(action.client.data);

      if (state.client && state.client.id === client.id) {
        if (!client.systemJobs && state.client.systemJobs)
          client.systemJobs = [...state.client.systemJobs];
      }

      return { ...state, client, loading: false };
    }
    case 'RECEIVE_CLIENT_PROCEDURES': {
      const newProcedures = action.procedures.map(formatData);
      const procedures = action.append
        ? [...state.procedures, ...newProcedures]
        : newProcedures;
      return { ...state, procedures };
    }
    case 'RECEIVE_CLIENT_PROVIDERS': {
      const newProviders = action.providers.map(formatData);
      const providers = action.merge
        ? [...state.providers, ...newProviders]
        : newProviders;
      return { ...state, providers };
    }
    case 'RECEIVE_CLIENT_SYSTEM_JOBS':
      return {
        ...state,
        client: {
          ...state.client,
          systemJobs: action.systemJobs.map(formatData),
        },
      };
    case 'RECEIVE_CLIENT_PROCEDURES_LOADING':
      return { ...state, proceduresLoading: action.proceduresLoading };
    case 'RECEIVE_CLIENT_LOADING':
      return { ...state, loading: action.clientLoading };
    default:
      return state;
  }
};
