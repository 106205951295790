import { css } from 'styled-components';
import theme from 'app/theme';

const {
  lightGreen,
  warning,
  ok,
  critical,
  purple,
  blue,
  medGrey,
  ['grey-4-a']: grey4a,
} = theme.global.colors;

export default css`
  .completed {
    opacity: 0.75;
    .entry-heading,
    .description {
      text-decoration: line-through !important;
    }
    .grommetux-anchor {
      text-decoration: none !important;
    }
    button {
      opacity: 0.75;
    }
  }

  .active-status {
    color: ${lightGreen};
    font-weight: 600;
  }

  .grommetux-list-item {
    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }
  }

  .grommetux-list {
    > li {
      &:hover {
        background-color: inherit;
        transition: 0 !important;
      }
    }
  }

  .hover-link {
    &:hover {
      color: ${blue} !important;
      transition: 0 !important;
      svg {
        fill: ${blue} !important;
        stroke: ${blue} !important;
        transition: 0 !important;
      }
    }
  }

  .status-grommet {
    color: ${purple};
    > span {
      color: ${purple};
    }
    svg {
      fill: ${purple} !important;
      stroke: ${purple} !important;
      transition: 0 !important;
    }
  }
  .status-ok {
    color: ${ok};
    > span {
      color: ${ok};
    }
    svg {
      fill: ${ok} !important;
      stroke: ${ok} !important;
      transition: 0 !important;
    }
  }
  .status-critical {
    color: ${critical};
    > span {
      color: ${critical};
    }
    svg {
      fill: ${critical} !important;
      stroke: ${critical} !important;
      transition: 0 !important;
    }
  }
  .status-warning {
    color: ${warning};
    > span {
      color: ${warning};
    }
    svg {
      fill: ${warning} !important;
      stroke: ${warning} !important;
      transition: 0 !important;
    }
  }

  .faded-text {
    color: ${grey4a};
    > span {
      color: ${grey4a};
      &:hover {
        color: inherit;
      }
    }
    svg {
      fill: ${grey4a} !important;
      stroke: ${grey4a} !important;
      transition: 0 !important;
    }
  }
  .regular {
    font-weight: 400;
  }
  .semibold {
    font-weight: 500;
  }

  .secondary {
    color: ${medGrey};
  }
`;
